import React, {  useState,useEffect } from "react";
import { Form, Row, Col, Radio, Alert } from 'antd';
import { useForm } from "antd/lib/form/Form";
import ConnectStateProps from "../../utils/state.connect";
import OthersBusiness from "../onthego.transfer/others.business/others.business.component";
import MyselfNewTransfer from '../onthego.transfer/Myself'
import SomeoneComponent from "../onthego.transfer/others.SomeOneElse/someone.component";
import { getTabsDetails } from "./api";
import {isErrorDispaly} from "../../api/apiCalls";
const FiatAddress = ({ onSubmit, onAddressOptionsChange,selectedAddress, onContinue, PayeeLu = [], emailExist = false, countries = [], states = [], fiatAddress, onTheGoObj,reasonAddress, ...props }) => {
    const [form] = useForm();
    const addrType = (selectedAddress?.addressType || props.userProfile?.isBusiness)
  ? selectedAddress?.addressType?.toLowerCase() || "ownbusiness"
  : "myself";
    const [addressOptions, setAddressOptions] = useState({ addressType:addrType, transferType: props.currency === "EUR" ? "sepa" : "domestic" });
      const [tabsData,setIsTabsData]=useState([]);
      const [errorMessage,setErrorMsg]=useState(null);
        useEffect(()=>{
            getTabData();
        },[])
        const getTabData=async()=>{
            let res = await getTabsDetails();
            if(res.ok){
                setIsTabsData(res.data);
            }else{
                setErrorMsg(isErrorDispaly(res));
            }
        }
    return <>
        <Form
            form={form}
            onFinish={onSubmit}
            autoComplete="off"
            initialValues={fiatAddress}
        >
            <Form.Item
                name="addressType"
                className="custom-label text-center mb-0"
            >
                <Row gutter={[16, 16]}>
                {errorMessage && (
                <Alert
                  style={{textAlign:"start"}}
                  type="error"
                  showIcon
                  closable={false}
                  description={errorMessage}
                />
              )}
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="d-flex justify-start">
                       <Radio.Group
                        defaultValue={props.userProfile?.isBusiness?"ownbusiness":"myself"}
                        className="new-custom-radiobtn"
                        onChange={(value) => {
                            setAddressOptions({ ...addressOptions, addressType: value.target.value });
                            onAddressOptionsChange({ ...addressOptions, addressType: value.target.value });
                        }}
                    >
                        {tabsData?.map((item) => {
                            if (item.status === "True") {
                                return (
                                    <Radio.Button
                                        key={item.addressbooktabs}
                                        value={item.addressbooktabs=="MySelf" ? props.userProfile?.isBusiness ? "ownbusiness":"myself" : item.addressbooktabs=="Individuals" && "someoneelse" || "otherbusiness" }
                                        defaultChecked={item.addressbooktabs === "MySelf"}
                                    >
                                        <span className="lg icon" />
                                        {item.addressbooktabs === "MySelf" ? props.userProfile?.isBusiness ? "My Company": "My Self" : item.addressbooktabs}
                                    </Radio.Button>
                                );
                            }
                            return null;
                        })}
                    </Radio.Group>
                             {/* <Radio.Button value={props.userProfile?.isBusiness?"ownbusiness":"myself"}><span className="lg icon" />{props.userProfile?.isBusiness ? "My Company" : "My Self"}</Radio.Button>
                            <Radio.Button value="someoneelse"><span className="lg icon" />Individuals</Radio.Button>
                            <Radio.Button value="otherbusiness"><span className="lg icon" />Other Business</Radio.Button> */}
                    </Col>
                </Row>
            </Form.Item>
        </Form>
        {(addressOptions.addressType === "ownbusiness"||addressOptions.addressType === "myself") && <MyselfNewTransfer currency={props.currency} type={props.type} onContinue={(obj) => onContinue(obj)} {...props} isBusiness={props.userProfile?.isBusiness} selectedBank={props?.selectedBank}
            onTheGoObj={{amount:props.amount}} selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId}></MyselfNewTransfer>}
        {addressOptions.addressType === "otherbusiness" && <OthersBusiness selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId} type={props.type} isUSDTransfer={props.currency === "USD" ? true : false} onContinue={(obj) => onContinue(obj)} amount={props.amount} reasonAddress={reasonAddress} selectedBank={props?.selectedBank} />}
        {addressOptions.addressType === "someoneelse" && <SomeoneComponent selectedAddress={selectedAddress} selectedAccountId={props.selectedAccountId} addressType={addressOptions.addressType} type={props.type} currency={props.currency} onContinue={(obj) => onContinue(obj)} onTheGoObj={{amount:props.amount}}reasonAddress={reasonAddress} selectedBank={props?.selectedBank}/>}
    </>
}

export default ConnectStateProps(FiatAddress);