


let initialState = {
    isTransactionError:null,
    isStatusModal:false,
    selectedobj:{},
    isBtnLoader:false,
    selecedState:null,
    bankLuData:[],
    provider:null,
    isError:null,
    sendPermission:null,
    receivePermission:null,
    transferpermission:null,
    receiveEnable:false,
    isModalVisible:false,
    isCheck:false,
    digitalWalletEnable:false,
    error:"",
    sendEnable:false,
    accountData:[],
    seelctedAccount:{},
    isRefreshDashBoard:false,
    showModal:false,
    selectedCoin:{},
    load:false,
    downloadLoader:false,
    viewData:null,
    isClosedAccount:false,
    isDepositModal:false,
    depositData:{},
    showIframe: false,
    iframeUrl:null,
    btnLoading:false,
    isDepositError:null,
    currency:''
};

export default function openPaydReducer(state = initialState, action) {
    switch (action.type) {
        case "isTransactionError":
            state = { ...state, isTransactionError: action.payload };
            return state;
            case "isStatusModal":
            state = { ...state, isStatusModal: action.payload };
            return state; 
            case "selectedobj":
            state = { ...state, selectedobj: action.payload };
            return state; 
            case "isBtnLoader":
            state = { ...state, isBtnLoader: action.payload };
            return state; 
            case "selecedState":
            state = { ...state, selecedState: action.payload };
            return state; 
            case "bankLuData":
            state = { ...state, bankLuData: action.payload };
            return state; 
            case "provider":
            state = { ...state, provider: action.payload };
            return state; 
            case "isError":
            state = { ...state, isError: action.payload };
            return state; 
            case "sendPermission":
            state = { ...state, sendPermission: action.payload };
            return state; 
            case "receivePermission":
            state = { ...state, receivePermission: action.payload };
            return state;
            case "transferpermission":
            state = { ...state, transferpermission: action.payload };
            return state;
            case "receiveEnable":
            state = { ...state, receiveEnable: action.payload };
            return state;
            case "isModalVisible":
            state = { ...state, isModalVisible: action.payload };
            return state;
            case "isCheck":
            state = { ...state, isCheck: action.payload };
            return state;
            case "digitalWalletEnable":
            state = { ...state, digitalWalletEnable: action.payload };
            return state;
            case "error":
            state = { ...state, error: action.payload };
            return state;
            case "sendEnable":
            state = { ...state, sendEnable: action.payload };
            return state;
            case "accountData":
            state = { ...state, accountData: action.payload };
            return state;
            case "seelctedAccount":
            state = { ...state, seelctedAccount: action.payload };
            return state;
            case "isRefreshDashBoard":
            state = { ...state, isRefreshDashBoard: action.payload };
            return state;
            case "showModal":
            state = { ...state, showModal: action.payload };
            return state;
            case "selectedCoin":
            state = { ...state, selectedCoin: action.payload };
            return state;
            case "load":
            state = { ...state, load: action.payload };
            return state;
            case "downloadLoader":
            state = { ...state, downloadLoader: action.payload };
            return state;
            case "viewData":
            state = { ...state, viewData: action.payload };
            return state;
            case "isClosedAccount":
            state = { ...state, isClosedAccount: action.payload };
            return state;
            case "isDepositModal":
            state = { ...state, isDepositModal: action.payload };
            return state;
            case "depositData":
                state = { ...state, depositData: action.payload };
                return state;
            case "showIframe":
                state = { ...state, showIframe: action.payload };
                return state;
             case "iframeUrl":
                state = { ...state, iframeUrl: action.payload };
            return state;
            case "btnLoading":
                state = { ...state, btnLoading: action.payload };
            return state;
            
            case "isDepositError":
                state = { ...state, isDepositError: action.payload };
            return state;
            case "currency":
                state = { ...state, currency: action.payload };
            return state;
        default:
            return state;
    }
 
}