import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { profileSuccess } from "../reducers/authReducer";
import { withCookies} from 'react-cookie';
import { getmemeberInfo } from '../reducers/configReduser';
import { store } from '../store/index';
const CallbackPage =(props)=> {
    const { auth: { deviceToken } } = store.getState();
useEffect(()=>{
    handleRedirect();
    if(deviceToken){
        props.getmemeberInfoa()
    }
},[deviceToken])

 const handleRedirect = () => {
    if(localStorage.getItem('myurl')){
        props.history.push(localStorage.getItem('myurl'));
        localStorage.removeItem("myurl");
    }else if(!window.location.pathname.includes('dashboard')&&!window.location.pathname.includes('createAccount')){
        props.history.push("/dashboard");
        localStorage.removeItem("__url");
    }
    }
 
        return (<>
                <div className="loader">Loading .....</div>
            </>  )
        
    }

const mapStateToProps = ({ oidc }) => {
    return { oidc }
}
const mapDispatchToProps = dispatch => {
    return { updateProfile: (info) => { dispatch(profileSuccess(info)) },getmemeberInfoa:(useremail)=>{
        dispatch(getmemeberInfo(useremail));
    }, }
}
export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(CallbackPage)));