
import React from 'react';
import { toDataSourceRequestString, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import moment from 'moment';
import { excellExportSubject } from "./subscribir";
import { store } from '../../store';
import { Spin } from 'antd';

const filterOperators = {
    'text': [
        { text: 'grid.filterContainsOperator', operator: 'contains' },
        { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
        { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
    ],
    'numeric': [
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' }
    ],
    'date': [
        { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
        { text: 'grid.filterAfterOperator', operator: 'gt' },
        { text: 'grid.filterBeforeOperator', operator: 'lt' },
        { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
    ],
    'datetime': [
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
        { text: 'grid.filterAfterOperator', operator: 'gt' },
        { text: 'grid.filterBeforeOperator', operator: 'lt' },
        { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
    ],
    'boolean': [
        { text: 'grid.filterEqOperator', operator: 'eq' }
    ]
}
const convertUTCToLocalTime = (dateString) => {
	let date = new Date(dateString);
	const milliseconds = Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
		date.getSeconds()
	);
	const localTime = new Date(milliseconds);
	return localTime.toISOString();
};
export function withState(WrappedGrid) {
    return class StatefullGrid extends React.Component {
        constructor(props) {
            super(props);
            this.state = { dataState: { skip: 0, take: 10 }, additionalParams: null, data: [], isLoading: false };
            this.excelRef = React.createRef();
			this.exportSubscriber = excellExportSubject.subscribe(() => {
				if (this.excelRef?.save) {
					let workbook = this.excelRef.workbookOptions(); // get the workbook.
                    workbook.sheets[0].rows.map((item, index) => {
                        if (item.type === "data") {
                            for (const i in this.props.columns) {
                                    const idx = this.props.columns.length === item.cells.length ? i : (i - 1);
                                    if (this.props.columns[i].filterType === "date") {
                                    if (item.cells[idx].value)
                                    item.cells[idx].value =moment(convertUTCToLocalTime(item.cells[idx].value)).format("DD/MM/YYYY hh:mm:ss A");
                                }
                                if (this.props.columns[i].filterType === "numeric") {
                                    if (item.cells[idx].value)
                                        item.cells[idx].value = this.numberWithCommas(item.cells[idx].value);
                                }
                            }
                        }
                    });
                    this.excelRef.save(workbook);
				}
			});
        }
        numberWithCommas(x) {
            return x;
        }
        refreshGrid() {
            this.fetchData(this.state.dataState);
        }
        loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );
        exportExcel=()=>{
            if (this.excelRef?.save) {
                let workbook = this.excelRef.workbookOptions(); // get the workbook.
                workbook.sheets[0].rows.map((item, index) => {
                    if (item.type === "data") {
                        for (const i in this.props.columns) {
                                const idx = this.props.columns.length === item.cells.length ? i : (i - 1);
                                if (this.props.columns[i].filterType === "date") {
                                if (item.cells[idx].value)
                                item.cells[idx].value =moment(convertUTCToLocalTime(item.cells[idx].value)).format("DD/MM/YYYY hh:mm:ss A");
                            }
                            if (this.props.columns[i].filterType === "numeric") {
                                if (item.cells[idx].value)
                                    item.cells[idx].value = this.numberWithCommas(item.cells[idx].value);
                            }
                        }
                    }
                });
                this.excelRef.save(workbook);
            }
        }
        render() {
            return (
                <div style={{ position: 'relative' }}>
                    {this.state.isLoading && this.loadingPanel} <>
                    <Spin spinning={this.state.isLoading} >
                    <ExcelExport data={this.state.data} fileName={this.props?.excelFileName} ref={(exporter) => { this.excelRef = exporter }}>
                    <WrappedGrid
                        sortable={true}
                        resizable={true}
                        filterOperators={filterOperators}
                        pageable={{ pageSizes: [5, 10, 20, 30, 40, 50, "All"] }}
                        {...this.props}
                        total={this.state.total}
                        data={this.state.data}
                        skip={this.state.dataState.skip}
                        pageSize={this.state.dataState.take}
                        filter={this.state.dataState.filter}
                        sort={this.state.dataState.sort}
                        onDataStateChange={this.handleDataStateChange}
                    /></ExcelExport></Spin></>
                </div>
            );
        }

        componentDidMount() {
            this.fetchData(this.state.dataState);
        }

        handleDataStateChange = (changeEvent) => {
            let _dataState = changeEvent.dataState;
            if (isNaN(_dataState.take)) {
                _dataState.take = this.state.total
            }
            this.setState({ dataState: _dataState });
            this.fetchData(_dataState);
        }

        fetchData(dataState) {
            if (dataState.filter) {
                dataState.filter.filters?.map((item) => {
                    item.filters?.map((value) => {
                        if (value.operator === "gte" || value.operator === "gt" || value.operator === "lte" || value.operator === "lt") {
                            value.value = value.value ? ((value.operator == 'lte' || value.operator == "gt") ? new Date(moment(value.value).format('YYYY-MM-DDT23:59:59')) : new Date(moment(value.value).format('YYYY-MM-DDT00:00:00'))) : null;
                        }
                    })
                })
            }
            this.setState({ ...this.state, isLoading: true })
            setTimeout(() => this.setState({ ...this.state, isLoading: false }), 5000);
             const { auth: { deviceToken } } = store.getState();
            let queryStr = `${toDataSourceRequestString(dataState)}`; // Serialize the state.
            const hasGroups = dataState.group && dataState.group.length;
            if (this.props.additionalParams) {
                let _additionalParams = '';
                for (let key in this.props.additionalParams) {
                    _additionalParams = _additionalParams + `/${this.props.additionalParams[key]}`
                }
                queryStr = _additionalParams+'?'+queryStr ;
            }else{
                queryStr ='?'+queryStr
            }
            const base_url = this.props.url;
            const init = { method: 'GET', accept: 'application/json', headers: { "Authorization": `Bearer ${deviceToken}` } };

            fetch(`${base_url}${queryStr}`, init)
                .then(response => response.json())
                .then(({ data, total }) => {
                    this.setState({
                        data: hasGroups ? translateDataSourceResultGroups(data) : data,
                        total,
                        dataState,
                        isLoading: false
                    });
                });
        }
    }
}