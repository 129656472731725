import React, { useEffect, useState } from "react";

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Alert from "antd/lib/alert";

import { getReceiveViewData } from "./api";
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from "../shared/loader";
import { isErrorDispaly } from "../../api/apiCalls";

const { Text, Title } = Typography;

const ReceiveFund = (props) => {
  const [loading, setIsLoading] = useState(false);
  const [fiatAddress, setFiatAddress] = useState({});
  const [message, setMessage] = useState("");
  useEffect(() => {
    viewAddress();
  }, []);

  
  const viewAddress = async () => {
    setIsLoading(true);
    let response = await getReceiveViewData(props?.reciveId || props?.receiveData?.receiveDetailsObj.id);
    if (response.ok) {
      setFiatAddress(response.data);
      setIsLoading(false);
    }
    else {
      setMessage(isErrorDispaly(response));
      setIsLoading(false);
    }
  };

  return <>
    {message !== undefined && message !== null && message !== "" && (
      <div style={{ width: "100%" }}>
        <Alert
          className="w-100 mb-16"
          type="error"
          description={message}
          showIcon
        />
      </div>
    )}
    <div className="py-4" style={{ alignItems: 'baseline' }}>
                <Title className="text-white fs-20 fw-600" > {'Your account details are as follows:'}</Title>
              </div>
              {loading ? (<Loader />) : ( <div className="cust-summary-new adressbook-fiatview kpi-List">
     
        
         {fiatAddress.currency == "USD" && <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Beneficiary Name</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.fullName||"---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Beneficiary Address</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.beneficiaryAddress || "---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle " style={{ alignItems: 'baseline' }}>
                <label className="kpi-label ">Beneficiary Account No</label>
                <div>  <CopyToClipboard text={fiatAddress.accountNumber} options={{ format: 'text/plain' }}>
                  <Text copyable={fiatAddress.accountNumber &&{ tooltips: ["Copy", 'Copied'] }} className="kpi-val" >
                    {fiatAddress.accountNumber||"---"}
                  </Text>
                </CopyToClipboard></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle " style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Routing No</label>
                <div>  <CopyToClipboard text={fiatAddress.routingNumber} options={{ format: 'text/plain' }}>
                  <Text copyable={fiatAddress.swiftCode &&{ tooltips: ["Copy", 'Copied'] }} className="kpi-val" >
                    {fiatAddress.swiftCode ||"---"}
                  </Text>
                </CopyToClipboard></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">SWIFT / BIC Code</label>
                <div>  <CopyToClipboard text={fiatAddress.swiftCode
                } options={{ format: 'text/plain' }}>
                  <Text copyable={fiatAddress.bic &&{ tooltips: ["Copy", 'Copied'] }} className="kpi-val" >
                     {fiatAddress.bic ||"---"}
                  </Text>
                </CopyToClipboard></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Beneficiary Bank Name</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.beneficiaryBankName ||"---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Beneficiary Bank Address</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.beneficiaryBankAddress||'---'}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Bank Reference No</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.referenceNumber||'---'}
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
          }
           {(fiatAddress.currency == "EUR"||fiatAddress.currency == "GBP") && <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Name</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.fullName||"---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Address</label>
                <div>
                  <Text className="kpi-val">
                  {fiatAddress.beneficiaryAddress||"---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">IBAN</label>
                <div> <CopyToClipboard text={fiatAddress.iban} options={{ format: 'text/plain' }}>
                  <Text copyable={fiatAddress.iban && { tooltips: ["Copy", 'Copied'] }} className="kpi-val" >
                    {fiatAddress.iban||"---"}
                  </Text>
                </CopyToClipboard></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label ">Swift / BIC Code</label>
                <div> <CopyToClipboard text={fiatAddress.iban} options={{ format: 'text/plain' }}>
                  <Text copyable={fiatAddress.bic && { tooltips: ["Copy", 'Copied'] }} className="kpi-val" >
                    {fiatAddress.bic ||"---"}
                  </Text>
                </CopyToClipboard></div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Bank Name</label>
                <div>
                  <Text className="kpi-val" >
                    {fiatAddress.beneficiaryBankName ||"---"}
                  </Text>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Bank Address</label>
                <div>
                  <Text className="kpi-val">
                    {fiatAddress.beneficiaryBankAddress||'---'}
                  </Text>
                </div>
              </div>
            </Col>
            {/* <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
              <div className="kpi-divstyle" style={{ alignItems: 'baseline' }}>
                <label className="kpi-label">Reference No</label>
                <div className="summarybal">
                  <Text className="kpi-val">
                    {fiatAddress.referenceNumber||'---'}
                  </Text>
                </div>
              </div>
            </Col> */}
          </Row>
          }
        
    </div>)}
  </>
}
const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo

  };
};
export default connect(connectStateToProps)(ReceiveFund);