import { Form, Row, Col, Typography, Input, Button, Alert, Image, Spin, Tabs,Select } from "antd";
import React, { Component } from "react";
import { validateContentRule,validateFeild } from "../../../utils/custom.validator";
import AddressDocumnet from "../document.upload";
import { RecipientAddress } from "./recipient.details";
import { createPayee, fetchIBANDetails, payeeAccountObj, savePayee,getRelationDetails,getReasonforTransferDetails } from "../api";
import BusinessTransfer from "./transfer";
import ConnectStateProps from "../../../utils/state.connect";
import Loader from "../../shared/loader";
import alertIcon from '../../../assets/images/pending.png';
import success from '../../../assets/images/success.svg';
import { isErrorDispaly } from "../../../api/apiCalls";
// import success from '../../../assets/images/success.png';
const { Paragraph, Text, Title } = Typography;
const { TextArea } = Input;
const {Option}=Select;
class OthersBusiness extends Component {
    form = React.createRef();
    formRef =React.createRef();
    state = {
        errorMessage: null,
        ibanDetailsLoading: false,
        isLoading: true,
        details: {},
        ibanDetails: {},
        docDetails: {}, isBtnLoading: false,
        showDeclartion: false,
        iBanValid:false,
        selectedTab:'sepa',
        documents:null,
        selectedRelation:null,
        selectedReasonforTransfer:null,
        reasonForTransferData:null,
        reasonDocuments:null,
    };
    componentDidMount() {
        this.loadDetails();
        this.getRelationData();
        this.getReasonForTransferData();
    }
    loadDetails = async () => {
        this.setState({ ...this.state, errorMessage: null, isLoading: true});
        const response = await createPayee(this.props.selectedAddress?.id || "", "business");
        if (response.ok) {
            let data = response.data;
            if (!data?.payeeAccountModels) {
                data.payeeAccountModels = [payeeAccountObj()];
            }
            if (this.props.selectedAddress) {
                const accountDetails = data.payeeAccountModels[0];
                data = { ...data, ...accountDetails,line1:data.line1,line2:data.line2,line3:data.line3,bankAddress1:accountDetails.line1,bankAddress2:accountDetails.line2 };
                delete data["documents"];
                if (data?.iban) {
                    this.handleIbanChange({ target: { value: data?.iban } });
                }
            }
            const ibanDetails=response.data?.payeeAccountModels[0]||{}
            this.setState({ ...this.state, errorMessage: null, details: data,ibanDetails }, () => {
                this.setState({ ...this.state, isLoading: false });
           });
        } else {
            this.setState({ ...this.state, errorMessage: isErrorDispaly(response), isLoading: false, details: {} });
        }
    }
    onIbanValidate = (e) => {
        let value = e ? e: this.form.current?.getFieldValue('iban');
        if (value?.length >= 10) {
            if (value &&!/^[A-Za-z0-9]+$/.test(value)) {
                this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {}, isValidateLoading: true, isValidateMsg: true, errorMessage: null});
                this.form.current?.validateFields(["iban"], this.validateIbanType)
            }
            else {
                this.setState({ ...this.state, isValidCheck: true, isShowValid: false, isValidateLoading: true});
                this.handleIbanChange({ target: { value: value, isNext: true }});
            }
        }
        else {
            this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {}, isValidateLoading: true, isValidateMsg: true, errorMessage: null});
            this.form.current?.validateFields(["iban"], this.validateIbanType)
        }
    }

     validateIbanType = (_, value) => {
        this.setState({ ...this.state, isValidateLoading: false});
        if ((!value&&this.state.isShowValid)||!value) {
            this.setState({ ...this.state, iBanValid : false, ibanDetails: {}});
            return Promise.reject("Is required");
        } else if ((!this.state.iBanValid&&this.state.isShowValid) || value?.length < 10) {
            this.setState({ ...this.state, ibanDetails: {}});
            return Promise.reject("Please input a valid IBAN");
        } else if (
            value &&this.state.isShowValid&&
            !/^[A-Za-z0-9]+$/.test(value)
        ) {
            this.setState({ ...this.state, iBanValid : false, ibanDetails: {}});
            return Promise.reject(
                "Please input a valid IBAN"
            );
        }
        else {
            return validateContentRule(_, value);
        }
    };
    handleIbanChange = async ({ target: { value,isNext } }) => {
        this.setState({ ...this.state, ibanDetails: {}, enteredIbanData: value, isShowValid: false,errorMessage: null});
        if (value?.length >= 10 && isNext) {
            this.setState({ ...this.state, errorMessage: null, ibanDetailsLoading: true,iBanValid:true });
            const response = await fetchIBANDetails(value,this.props?.selectedBank);
            if (response.ok) {
                if(response.data && (response.data?.routingNumber || response.data?.bankName)){
                    this.setState({ ...this.state, ibanDetails: response.data, enteredIbanData: value, ibanDetailsLoading: false, errorMessage: null, iBanValid:true, isValidateLoading: false });
                }else{
                    if(this.state.ibanDetails && !this.state.ibanDetails?.routingNumber|| !this.state.ibanDetails?.bankName) {
                        this.setState({ ...this.state, ibanDetails: {}, ibanDetailsLoading: false, errorMessage: null, iBanValid:false, isValidateLoading: false });
                        this.setState({ ...this.state, errorMessage: "No bank details are available for this IBAN number", isLoading: false, isBtnLoading: false });
                        this.formRef.current?.scrollIntoView();
                        return;
                    }
                }
            } else {
                this.setState({ ...this.state, enteredIbanData: value, ibanDetailsLoading: false,iBanValid:false, errorMessage: isErrorDispaly(response), isValidateLoading: false, ibanDetails: {}});
            }
        }
        else{
            this.setState({ ...this.state, ibanDetailsLoading: false,iBanValid:false, enteredIbanData: value, isShowValid: false, isValidateLoading: false,ibanDetails: {},errorMessage: null})
        }
    }
    submitPayee = async (values) => {
        let { details, ibanDetails,selectedTab } = this.state;
        let _obj = { ...details, ...values };
        if (Object.hasOwn(values, 'iban')) {
            this.setState({ ...this.state, errorMessage: null });
            if ((!ibanDetails || Object.keys(ibanDetails).length == 0)) {
                this.setState({ ...this.state, errorMessage: "Please click validate button before saving", isLoading: false, isBtnLoading: false });
                this.formRef.current?.scrollIntoView();
                return;
            }
        }
        _obj.others =values?.relation==="Others"? values?.others:null;
        _obj.payeeAccountModels[0].line1 = ibanDetails.bankAddress;
        _obj.payeeAccountModels[0].line1 = selectedTab === "internationalIBAN" ? ibanDetails?.bankAddress : values.bankAddress1;
        _obj.payeeAccountModels[0].line2 = values.bankAddress2;
        _obj.payeeAccountModels[0].city = ibanDetails?.city;
        _obj.payeeAccountModels[0].state = ibanDetails?.state;
        _obj.payeeAccountModels[0].country = ibanDetails?.country;
        _obj.payeeAccountModels[0].postalCode = ibanDetails?.zipCode;
        _obj.payeeAccountModels[0].bankBranch = ibanDetails?.branch;
        _obj.payeeAccountModels[0].bic=ibanDetails?.routingNumber;
        _obj.payeeAccountModels[0].docrepoitory = this.state.documents;
        _obj.payeeAccountModels[0].iban = values?.iban;
        _obj.payeeAccountModels[0].currencyType = "Fiat";
        _obj.payeeAccountModels[0].walletCode = "EUR";
        _obj.payeeAccountModels[0].accountNumber = values?.accountNumber;
        _obj.payeeAccountModels[0].swiftRouteBICNumber = values?.swiftRouteBICNumber;
        _obj.payeeAccountModels[0].userCreated = this.props?.userProfile?.userName;
        _obj.payeeAccountModels[0].bankName = (selectedTab === "internationalIBAN" ||selectedTab === "sepa" )? ibanDetails?.bankName :  values?.bankName;
        delete _obj.payeeAccountModels[0]["adminId"] // deleting admin id
        _obj.addressType = "otherbusiness";
        _obj.transferType = selectedTab;
        _obj.createdBy = this.props?.userProfile?.userName;
        this.setState({ ...this.state, isLoading: false, errorMessage: null, isBtnLoading: true });
        const response = await savePayee(_obj);
        if (response.ok) {
            if (this.props.type !== "manual") {
                this.props.onContinue(response?.data);
                this.props.reasonAddress(this.state.reasonDocuments);
            } else {
                this.setState({ ...this.state, errorMessage: null, isBtnLoading: false, showDeclartion: true });
            }

     } else {
            this.setState({ ...this.state, details: { ...this.state.details, ...values }, errorMessage: isErrorDispaly(response), isLoading: false, isBtnLoading: false });
            this.formRef.current.scrollIntoView(0,0)
        }

    }
    getRelationData=async()=>{
        let res = await getRelationDetails()
        if(res.ok){
            this.setState({...this.state,relationData:res.data,errorMessage:null})
        }else{
            this.setState({...this.state,errorMessage:isErrorDispaly(res),})
           
        }
    }
    handleRelation=(e)=>{
        this.setState({...this.state,selectedRelation:e})
        this.form.current?.setFieldsValue({others:null})
     
    }
    getReasonForTransferData=async()=>{
        let res = await getReasonforTransferDetails();
        if(res.ok){
            this.setState({...this.state,reasonForTransferData:res.data,errorMessage:null})
        }else{
            this.setState({...this.state,errorMessage: isErrorDispaly(res),})
           
        }
    }

    handleReasonTrnsfer=(e)=>{
        this.setState({...this.state,selectedReasonforTransfer:e})
        this.form.current.setFieldsValue({transferOthers:null})
    }
    handleTabChange = (key) => {
        this.form.current.resetFields();
        let _obj = { ...this.state.details}
        _obj.payeeAccountModels[0].documents=null
        this.setState({ ...this.state, selectedTab: key,errorMessage:null, ibanDetails: {}, iBanValid: false, enteredIbanData: null,documents:null,reasonDocuments:null,selectedRelation:null,selectedReasonforTransfer:null });
        this.form.current.setFieldsValue({transferOthers:null,favouriteName:null,beneficiaryName:null,
            relation:null,others:null,iban:null,accountNumber:null,swiftRouteBICNumber:null,bankName:null,
            bankAddress1:null,bankAddress2:null,reasonOfTransfer:null,line1:null,line2:null,line3:null})
       
    }
    render() {
        const {documents,selectedTab}=this.state;
        const { isUSDTransfer } = this.props;
        if (this.state.isLoading) {
            return <Loader />
        }
        if (this.state.showDeclartion) {
            return <div className="text-center">
                {process.env.REACT_APP_ISDOCUSIGN == "false" &&  <><Image width={80} preview={false} src={alertIcon} className="confirm-icons"/>
                <Title level={2} className="success-title">Declaration form sent successfully to your email</Title>
                <Text className="successsubtext">{`Declaration form has been sent to ${this.props.userProfile?.email}. 
                   Please sign using link received in email to whitelist your address. `}</Text>
                <Text className="successsubtext">{`Please note that your withdrawal will only be processed once your whitelisted address has been approved`}</Text>
                <div className="my-25"><Button onClick={() => this.props.onContinue({ close: true, isCrypto: false })} type="primary" className="mt-36 pop-btn text-textDark">BACK</Button></div> || <div className="success-pop text-center declaration-content">
       <Image  preview={false} src={success}  className="confirm-icon" />
       <Title level={2} className="success-title">Address saved successfully</Title>
       {/* <p className="text-white">Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved</p> */}
   </div> </>}
            </div>
        }
        if (isUSDTransfer) { return <BusinessTransfer type={this.props.type} amount={this.props?.amount} onContinue={(obj) => this.props.onContinue(obj)} selectedAddress={this.props.selectedAddress} selectedAccountId={this.props.selectedAccountId}/> }
        else {
            return( <>
             <div ref={this.formRef}></div>
                <Tabs className="cust-tabs-fait" onChange={this.handleTabChange} activeKey={this.state.selectedTab}>
            <Tabs.TabPane tab="SEPA Transfer" className="text-white" key={"sepa"} disabled={this.state.isEdit}/>
            {/* <Tabs.TabPane tab="SWIFT Transfer" className="text-white" key={"swifttransfer"} disabled={this.state.isEdit}/> */}
            </Tabs>
                {this.state.isLoading &&<Loader />}
                {this.state.errorMessage && <Alert type="error" showIcon closable={false} description={this.state.errorMessage} />}
                {!this.state.isLoading && <Form initialValues={this.state.details}
                    className="mb-0"
                    ref={this.form}
                    onFinish={this.submitPayee}
                    scrollToFirstError
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label mb-0"
                                name="favouriteName"
                                label={"Save Whitelist Name As"}
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'save whitelist name as'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                            >
                                <Input
                                    maxLength={100}
                                    className="cust-input"
                                    placeholder={"Save Whitelist Name As"}
                                />


                            </Form.Item>
                        </Col>
                    </Row>
                    <Paragraph 
                        className="adbook-head"
                    >Recipient's Details</Paragraph>
                  
                    <Row gutter={[8, 8]}>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label"
                                name="beneficiaryName"
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,lableName:'beneficiary name'})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                                label={
                                    "Beneficiary Name"
                                }
                            >
                                <Input
                                    className="cust-input"
                                    placeholder={"Beneficiary Name"}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label"
                                name="relation"
                                label={"Relationship To Beneficiary"}
                                required
                                rules={[
                                    {
                                        validator:(_,val)=>{
                                            return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true,})
                                        }
                                    },
                                    {
                                        whitespace: true,
                                        message: "Is required",
                                    },
                                ]}
                            >
                                <Select
                                    className="cust-input"
                                    maxLength={100}
                                    placeholder={"Relationship To Beneficiary"}
                                    optionFilterProp="children"
                                    onChange={(e)=>this.handleRelation(e)}
                                >
                                    {this.state.relationData?.map((item, idx) => (
                                    <Option key={idx} value={item.name}>
                                        {item.name}
                                    </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col>
                        {this.state.selectedRelation=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="others"
                            required
                            rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true})
                                    }
                                },
                                {
                                    whitespace: true,
                                    message: "Is required",
                                },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
                        <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                        <Paragraph className="sub-abovesearch code-lbl upload-btn-mt">Please upload supporting documents to prove your relationship with the beneficiary. E.g. Contracts, Agreements</Paragraph>
                        <AddressDocumnet documents={documents || null} onDocumentsChange={(docs) => {
                            this.setState({...this.state,documents:docs})
                            }} refreshData={selectedTab} type={"payee"}/>
                            </Col>
                        <RecipientAddress />
                    </Row>

                    <Paragraph className="adbook-head">Bank Details</Paragraph>
                    
                   {selectedTab==="sepa"&& <Row className="validateiban-content">
                    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                       <div className=" custom-btn-error">
                            <Form.Item
                                className="custom-forminput custom-label"
                                name="iban"
                                label={"IBAN"}
                                required
                                rules={[
                                    {
                                        validator: this.validateIbanType,
                                      },
                                ]}
                            >
                                <Input
                                    className="cust-input ibanborder-field"
                                    placeholder={"IBAN"}
                                   
                                    onChange={this.handleIbanChange}
                                    maxLength={50}
                                    addonAfter={<Button className={`px-24`}
                                    type="primary"
                                    loading={this.state.isValidateLoading} 
                                     onClick={() => this.onIbanValidate(this.state?.enteredIbanData)} >
                                       Validate
                                    </Button>}
                                    />

                            </Form.Item>
                            </div>
                       </Col>
                                <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="box basic-info alert-info-custom mt-16 kpi-List">
                        <Spin spinning={this.state.ibanDetailsLoading}>
                        {this.state.iBanValid && <Row>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                                            <div className="kpi-divstyle">
                                <label className="kpi-label">
                                        Bank Name
                                </label>
                                <div><Text className="kpi-val">{this.state.ibanDetails?.bankName || "-"}</Text></div>
                           </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                               <label className="kpi-label ">BIC</label>
                                <div><Text className="kpi-val">{this.state.ibanDetails?.routingNumber || "-"}</Text></div>
                               </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                               <label className="kpi-label ">Branch</label>
                                <div><Text className="kpi-val">{this.state?.ibanDetails?.branch || "-"}</Text></div>
                              </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                     <label className="kpi-label">Country</label>
                                <div><Text className="kpi-val">{this.state?.ibanDetails?.country || "-"}</Text></div>
                             </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label">State</label>
                                <div><Text className="kpi-val">{this.state?.ibanDetails?.state || "-"}</Text></div>
                             </div>
                            </Col>
                            <Col  xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                 <label className="kpi-label">City</label>
                                <div><Text className="kpi-val">{this.state?.ibanDetails?.city || "-"}</Text></div>
                             </div>
                            </Col>
                            <Col  xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                   <label className="kpi-label">Zip</label>
                                <div><Text className="kpi-val">{this.state?.ibanDetails?.zipCode || "-"}</Text></div>
                             </div>
                            </Col>
                        </Row>}
                        {!this.state.iBanValid && !this.state.ibanDetailsLoading &&<span className="info-details">No bank details available</span>}
                        </Spin>
                       
                    </Col>
                    </Row>}
            
                    <Row gutter={[8, 8]}>
                    {(this.state.selectedTab==="swifttransfer") && (
                        <>
                          {" "}
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="fw-300 mb-8 px-4 text-white-50  custom-forminput custom-label pt-8"
                              name="accountNumber"
                              label="Account Number"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Is required"
                                },
                                {
                                  validator: (_, value) => {
                                    if (
                                      value &&
                                      !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                      return Promise.reject(
                                        "Invalid Account Number"
                                      );
                                    } else {
                                      return validateContentRule(_, value);
                                    }
                                  }
                                }
                              ]}
                            >
                              <Input
                                className="cust-input"
                                placeholder="Account Number"
                                maxLength={50}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                              <Form.Item
                                className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                                name="swiftRouteBICNumber"
                                label={"Swift / BIC Code"}
                                required
                                rules={[
                                  {
                                    required: true,
                                    message: "Is required"
                                  },
                                  {
                                    validator: (_, value) => {
                                      if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                      ) {
                                        return Promise.reject("Invalid Swift / BIC Code"
                                          
                                        );
                                      } else {
                                        return validateContentRule(_, value);
                                      }
                                    }
                                  }
                                ]}
                              >
                                <Input
                                  className="cust-input"
                                  placeholder={"Swift / BIC Code"}
                                  maxLength={50}
                                />
                              </Form.Item>
                            </Col>
                       
                         <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="bankName"
                              label="Bank Name"
                              rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,required: true,maxLength:50,validateContentRule:true})
                                    }
                                },
                                {
                                    whitespace: true,
                                    message: "Is required",
                                },
                            ]}
                            >
                              <Input
                                className="cust-input"
                                placeholder="Bank Name"
                                maxLength={100}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="bankAddress1"
                              label="Bank Address 1"                             
                              rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Is required",
                                },
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,maxLength:50,validateContentRule:true})
                                    }
                                }
                            ]}
                            >
                              <TextArea
                                placeholder={"Bank Address 1"}
                                className="cust-input cust-text-area address-book-cust"
                                autoSize={{ minRows: 1, maxRows: 2 }}
                                maxLength={1000}
                              ></TextArea>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                              className="custom-forminput custom-label fw-300 mb-8 px-4 text-white-50 pt-8"
                              name="bankAddress2"
                              label="Bank Address 2"
                              rules={[
                                {
                                    validator:(_,val)=>{
                                        return validateFeild({val:val,maxLength:50,validateContentRule:true})
                                    }
                                },
                            ]}
                            >
                              <TextArea
                                placeholder={"Bank Address 2"}
                                className="cust-input cust-text-area address-book-cust"
                                autoSize={{ minRows: 1, maxRows: 2 }}
                                maxLength={1000}
                              ></TextArea>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                  </Row>
                  {this.props?.type !== "manual" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                                className="custom-forminput custom-label"
                                name="reasonOfTransfer"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message:"Is required",
                                    },
                                    {
                                        whitespace: true,
                                        message:"Is required",
                                    },
                                    {
                                        validator: validateContentRule,
                                    },
                                ]}
                                label={
                                    "Reason For Transfer"
                                }
                            >
                                 <Select
                                    className="cust-input"
                                    maxLength={100}
                                    placeholder= "Reason For Transfer"
                                    optionFilterProp="children"
                                    onChange={(e)=>this.handleReasonTrnsfer(e)}
                                >
                                    {this.state.reasonForTransferData?.map((item, idx) => (
                                    <Option key={idx} value={item.name}>
                                        {item.name}
                                    </Option>
                                    ))}
                                </Select> 
                            </Form.Item>
                        </Col>}
                        {this.state.selectedReasonforTransfer=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="transferOthers"
                            required
                            rules={[
                                {whitespace: true,
                                message: "Is required",
                                },
                                {
                                required: true,
                                message: "Is required",
                                },
                                {
                                validator: validateContentRule,
                            },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
                    <Paragraph className="sub-abovesearch code-lbl upload-btn-mt mb-0">Please Upload Supporting Documents To Justify Your Transfer Request. E.G. Invoice, Agreements</Paragraph>
                    <AddressDocumnet 
                            documents={this.state?.documents || this.state.details?.payeeAccountModels[0].documents} editDocument={this.state.isEdit} 
                            onDocumentsChange={(docs) => {                              
                            this.setState({...this.state,reasonDocuments:docs})
                            }} refreshData ={selectedTab}  type={"reasonPayee"}/>
                    <div className="text-right mt-12">
                                <Button
                                block
                                    htmlType="submit"
                                    size="large"
                                    className="pop-btn mb-36"
                                 
                                    disabled={this.state.ibanDetailsLoading}
                                    loading={this.state.isBtnLoading} >
                            {this.props.type === "manual" && "Save"}
                            {this.props.type !== "manual" && "Continue"}
                                    
                                </Button>
                    </div>
                </Form>}
            </>);
        }

    }
}
export default ConnectStateProps(OthersBusiness);