import React, { Component } from "react";
import { connect } from "react-redux";
import CreateAccount from "./createAccount";
import BusinessCreateAccount from "./businessCreateAccount";
import Feepage from "./feepage";
import ProgressPage from "./progressPage";
import { withRouter } from "react-router-dom";

class AccountCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.match.params.isBusiness == "true" ? "createBusinessAccount" :  "createPersonalAccount",//"feePage",
      monthlyFeeData: [],
      customerData: [],
      isLoading: false,
      errorMsg: null,
      isBusiness: false,
      permissions: {},
      FeesData:{}
    };
  }

  onChnagestep = (stepName) => {
    this.setState({ ...this.state, step: stepName });
  };

  onsetFees=(data)=>{
    this.setState({...this.state,FeesData:data})
  }
  renderStep = () => {
    const steps = {
      feePage: (
        <React.Fragment>
          <Feepage onChnagestep={(stepName) => this.onChnagestep(stepName)} onsetFees={(data)=>this.onsetFees(data)}
          />
        </React.Fragment>
      ),
      createPersonalAccount: (
        <React.Fragment>
          <CreateAccount  onChnagestep={(stepName) => this.onChnagestep(stepName)} currency={this.props.match.params?.currency} customerData={this.state.customerData} FeesData={this.state?.FeesData} isbank={this.props.match?.params?.isbank}/>
        </React.Fragment>
      ),
      createBusinessAccount: (
        <React.Fragment>
          <BusinessCreateAccount onChnagestep={(stepName) => this.onChnagestep(stepName)} currency={this.props.match.params?.currency} FeesData={this.state?.FeesData} isbank={this.props.match?.params?.isbank}/>
        </React.Fragment>
      ),
      progressPage: (
        <React.Fragment>
          <ProgressPage onChnagestep={(stepName) => this.onChnagestep(stepName)} currency={this.props.match.params?.currency}/>
        </React.Fragment>
      )
      
    };
    return steps[this.state.step];
  };
  render() {
    return <React.Fragment>{this.renderStep()}</React.Fragment>;
  }
}
const connectStateToProps = ({ userConfig,customerDetails }) => {
  return {
    userConfig: userConfig.userProfileInfo,
    customerDetails: customerDetails.data,
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(AccountCreation));
