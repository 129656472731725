import React, { useState } from 'react';

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Alert from "antd/lib/alert";
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Spin  from 'antd/lib/spin';

import { saveDigitalWallet } from './api'
import { connect } from 'react-redux';
import { setDigitalWalletStep } from "../../reducers/digitalWalletReducer";
import Verifications from '../onthego.transfer/verification.component/verifications';
import Confirmation from '../shared/confirmation';
import { isErrorDispaly } from "../../api/apiCalls";



const PastRecipientDetails = (props) => {
    const useDivRef = React.useRef(null);
    const [errorMsg, setErrorMsg] = useState();
    const [reviewDetailsLoading, setReviewDetails] = useState(false);
    const [verifyData, setVerifyData] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [isShowGreyButton,setIsShowGreyButton]=useState(false);
    const [summaryDetails] = useState([
        {
            name: 'Transfer Details',
            details: [
                {
                    key: 'Withdrawal Amount',
                    amount: props?.digitalWalletData?.summaryData.requestedAmount,
                    currency: props?.currencyObj?.currency
                },
                {
                    key: 'Total fees',
                    amount: props?.digitalWalletData?.summaryData.comission,
                    currency: props?.currencyObj?.currency
                },
                {
                    key: 'How much you will receive',
                    amount: props?.digitalWalletData?.summaryData.totalValue,
                    currency: props?.currencyObj?.currency
                },
            ]
        }])

   
      const isAllVerificationsFullfilled = (obj) => {
            const vdata=verifyData ||{}
            const vDetails=Object.keys(vdata).length===0?obj:verifyData;
            let _verficationDetails = { ...vDetails,...obj };
            let _verificationCount = 0;
            let _currentVerificationCount = 0;
            for (let key in _verficationDetails) {
              if (["isPhoneVerification", "isEmailVerification", "isAuthenticatorVerification"].includes(key) && _verficationDetails[key]) {
                _currentVerificationCount++;
              }
            }
        
            for (let key in _verficationDetails?.verifyData) {
              if (["isPhoneVerified", "isEmailVerification", "twoFactorEnabled"].includes(key) && _verficationDetails?.verifyData[key]) {
                _verificationCount++;
              }
            }
            return _verificationCount === _currentVerificationCount;
          }
    
    const changesVerification = (obj) => {
         setErrorMsg(null)
         setVerifyData(prevState => ({...prevState, ...obj}));
         setIsShowGreyButton(isAllVerificationsFullfilled(obj));
    }

    const onReviewDetailsLoading = (val) => {
        setReviewDetails(val);
    }

    const handleSummary = async () => {
        setBtnLoading(true);
        if (verifyData?.verifyData) {
            if (verifyData.verifyData.isPhoneVerified) {
                if (!verifyData.isPhoneVerification) {
                    setErrorMsg("Please verify phone verification code");
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (verifyData.verifyData.isEmailVerification) {
                if (!verifyData.isEmailVerification) {
                    setErrorMsg("Please verify  email verification code")
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (verifyData.verifyData.twoFactorEnabled) {
                if (!verifyData.isAuthenticatorVerification) {
                    setErrorMsg("Please verify authenticator code");
                    useDivRef.current.scrollIntoView();
                    setBtnLoading(false);
                    return;
                }
            }
            if (
                verifyData.verifyData.isPhoneVerified == "" &&
                verifyData.verifyData.isEmailVerification == "" &&
                verifyData.verifyData.twoFactorEnabled == ""
            ) {
                setErrorMsg("Without Verifications you can't send. Please select  verifications from security section",)
                useDivRef.current.scrollIntoView();
                setBtnLoading(false);
                return
            }
        }else if(verifyData.isLiveVerification){
            setBtnLoading(false);
        }
         else {
            setErrorMsg("Without Verifications you can't Procced.",)
            useDivRef.current.scrollIntoView();
            setBtnLoading(false);
            return
        }
        let obj = {
            amount: props?.amount,
            description: null,
            walletCode: props?.selectedBank?.currency || props?.currencyObj?.currency,
            transferBy: props?.userConfig?.isBusiness ? props?.userConfig?.businessName : props?.userConfig?.firstName  + " "+ props?.userConfig?.lastName ,
            attachements : null,
            provider:props?.selectedBank?.provider || props?.currencyObj?.provider,
            Ticks:props?.digitalWalletData?.summaryData?.wdTimeStamp
        }
        setBtnLoading(true);
        let response = await saveDigitalWallet(obj);
        if (response.ok) {
            setErrorMsg(null);
            props?.sendTransactionId(response?.data.transactionId);
            props?.changeStep("confimationSlip");
            setBtnLoading(false);
        }
        else {
            setErrorMsg(isErrorDispaly(response));
            setBtnLoading(false);
            useDivRef.current.scrollIntoView();
        }
    }
      const verificationsData=(data)=>{
        if(data?.isLiveVerification && !data?.twoFactorEnabled && !data?.isEmailVerification && !data?.isPhoneVerified ){
            setVerifyData(data);
            setIsShowGreyButton(true);
        }
      }
    return (
        <>
            <div ref={useDivRef}></div>
                {errorMsg && <Alert
                    description={errorMsg}
                    className="w-100 mb-16"
                    type="error"
                    showIcon
                />}
            <Spin spinning={reviewDetailsLoading}>
                <Form
                    name="advanced_search"
                    onFinish={() => handleSummary()}
                    autoComplete="off">
                            <Confirmation
                                transferDetails={summaryDetails}
                            />
                              <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                <Verifications onchangeData={(obj) => changesVerification(obj)} 
                                onReviewDetailsLoading={(val) => onReviewDetailsLoading(val)} 
                                onClosePopup={()=>props?.onClosePopup()} 
                                verificationsData={(data)=>verificationsData(data)}/>
                            </Col>
                    <Row gutter={24} className=" text-white">
                        <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                            <div className="text-right">
                                <Form.Item className="mb-0">
                                    <Button
                                        htmlType="submit"
                                        size="large"
                                        block
                                        className="pop-btn custom-send cust-disabled"
                                        style={{ backgroundColor: !isShowGreyButton && '#7087FF', borderColor: !isShowGreyButton && '#7087FF' }}
                                        loading={btnLoading}
                                        disabled={!isShowGreyButton}
                                    >
                                        Confirm & Continue
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>
    )
}

const connectStateToProps = ({ userConfig, digitalWalletData }) => {
    return {
        userConfig: userConfig.userProfileInfo, digitalWalletData

    };
};
const connectDispatchToProps = dispatch => {
    return {
        changeStep: (stepcode) => {
            dispatch(setDigitalWalletStep(stepcode))
        },
        dispatch
    }

}
export default connect(connectStateToProps, connectDispatchToProps)(PastRecipientDetails)
