import React, { useState } from 'react';
import { Component } from "react";
import Typography from "antd/lib/typography";
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu'
import Layout from "antd/lib/layout";
import Dropdown from "antd/lib/dropdown";
import logoColor from "../assets/images/SuisseBase.png";
import DefaultUser from "../assets/images/defaultuser.jpg";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import HeaderPermissionMenu from '../components/shared/permission/header.menu'
import {handleHeaderProfileMenuClick} from '../utils/pubsub'
import Wraplogout from '../auth/logout-component';
import OnthegoFundTransfer from '../components/onthego.transfer/index';
import { getAccountDetails } from "../api/apiCalls";
import { Drawer } from 'antd';
const { Text } = Typography;

class AppHeader extends Component {
  state={logoutuser:false,sendEnable:false,isRefreshDashBoard:false,
    marketplacepermission:false,open:false,placement:"left"}

 componentDidMount=()=>{
  this.setState({...this.state,sendPermissionName:this.props.sendPermissions?.actions[0].values,logoutuser:false,customerDetails:[],marketplacepermission:this.props.marketPlace?.actions[0].values})
  this.getCustomerDetails() 
}
  showAccount = () => {
    this.props.history.push("/dashboard");
  };
 
  getCustomerDetails=async()=>{
   let res = await getAccountDetails();
   if(res.ok){
   this.setState({...this.state,customerDetails:res?.data})
   }
  }
  routeToDashboard = (screen) => {
    if(screen =="dashboard"){
      this.props.history.push("/dashboard/banks")
    }else{
      this.props.history.push("/dashboard/marketplace")
    }
    this.setState({...this.state,sendEnable:false});
  };
  currentMenuKey(key){
    if (this.props.location.pathname.search(key) >= 0) {
      return this.props.location.pathname;
    }else{
      return key
    }
  }
  onMenuItemClick = (menuitem, menuKey) => {
    handleHeaderProfileMenuClick(menuitem, menuKey);
  }
  gotoSend=()=>{
    if(!this.props?.location.pathname.includes("createAccount")){
      this.setState({...this.state,sendEnable:true})
    }
    
  }
   refreshDashBoard = (isRefresh) => {
    this.setState({...this.state,isRefreshDashBoard:isRefresh});
  };
  showDrawer = () => {
    this.setState({...this.state,open:true})  
    };
   onClose = () => {
    this.setState({...this.state,open:false})  
    };
  render() {
    if(this.state.sendPermissionName !== this.props.sendPermissions?.actions[0]?.values ){
      this.setState({...this.state,sendPermissionName:this.props.sendPermissions?.actions[0]?.values})
    }
    if(this.state.marketplacepermission !== this.props.marketPlace?.actions[0]?.values ){
      this.setState({...this.state,marketplacepermission:this.props.marketPlace?.actions[0]?.values})
    }
    const userProfileMenu = (
      <Menu
      >
        <div className="profile-dropdown">
          <div className="profile-btn" >
                <Text
      
                  content="manage_account"
                  component={Button}
                  size="medium"
                  block
                  className="text-white fs-12 c-pointer"
                  onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/userprofile/1`,"_self")}

                  >
                  Manage your Account
                </Text>
                </div>
          <ul className="pl-0 drpdwn-list">
            <li>
              <Link onClick={() => {
                    this.setState({...this.state,logoutuser:true})
                  }}>
                <Text
                  content="AuditLogs"
                  component={Text}
                  className="text-white-30"
                >
                  Logout
                </Text>
              </Link>
            </li>
          </ul>
        </div>
      </Menu>
    );

    return (
      <>
         <Layout className="layout">
          <section className="haead-main">
          <div className="tlv-header" id="area">
            <div className="login-user">
              <ul className="header-logo pl-0">
              <li className="visible-mobile p-relative" 
              >
                    {this.state.open ? (
                      <span className="icon md close-white c-pointer" onClick={this.onClose}  />
                    ) : (
                      <span className="icon lg hamburg c-pointer" onClick={this.showDrawer} />
                    )}
                  </li>
                <li className="p-relative">
                  {
                    <img
                      src={logoColor}
                      className="tlv-logo light "
                      alt={"image,logo"}
                      onClick={this.routeToHome}
                    />
                  }
                </li>
              </ul>
            </div>
            <Menu
              theme="light"
              mode="horizontal"
              className="header-right mobile-headerview flex-head"
              selectedKeys={[this.props.location.pathname]}
            >
               {window.location.pathname.indexOf("/dashboard") > -1 && 
              <Menu.Item
                className="list-item header-send back-user"
                onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/cockpit`,"_self")}
              >
                   <Link> <span class="icon md lftarw-white c-pointer" ></span> Back To  SuisseBase Dashboard</Link>
              </Menu.Item>}
              <Menu.Item 
              onClick={() => this.routeToDashboard("dashboard", { key: "dashboard_marketplace", path: "/dashboard" })}
              className={`back-user ${window.location.pathname.includes("/dashboard/banks") && "list-item header-send text-white" || "list-item header-send"}`}>
         
                <Link>Dashboard</Link>
              </Menu.Item>
              {this.state?.marketplacepermission && (
              <Menu.Item 
              onClick={() => this.routeToDashboard("Send", { key: "dashboard_marketplace", path: "/dashboard/marketplace" })}
              className={`back-user ${window.location.pathname.includes("/dashboard/marketplace") && "list-item header-send text-white" || "list-item header-send"}`}>
         
                <Link>Marketplace</Link>
              </Menu.Item>)}
              </Menu>
            <Menu
              theme="light"
              mode="horizontal"
              className="header-right mobile-headerview"
              selectedKeys={[this.props.location.pathname]}
            >
              <Dropdown
                overlay={userProfileMenu}
                trigger={["hover"]}
                // placement="topRight"
                arrow
                overlayClassName="secureDropdown"
              >
                <Menu.Item key="3">
                 
                  {this.props.userConfig?.imageURL != null && (
                        <img
                            src={
                                this.props.userConfig?.imageURL
                                    ? this.props.userConfig?.imageURL
                                    : DefaultUser
                            }
                            className="user-profile"
                            alt={"image"}
                        />
                    )}
                    {this.props.userConfig?.imageURL === null && (
                        <img
                            src={
                                this.props.userConfig?.imageURL
                                    ? this.props.userConfig?.imageURL
                                    : DefaultUser
                            }
                            className="user-profile"
                            alt={"image"}
                        />
                    )}

                </Menu.Item>
              </Dropdown>
            </Menu>
          </div>
         {this.props?.userConfig && <HeaderPermissionMenu/>}
          </section>
          <Wraplogout isLogout={this.state.logoutuser} />
        </Layout>
        {this.state?.sendEnable && (
        <OnthegoFundTransfer
          onClose={() => {
           this.setState({...this.state,sendEnable:false})
            this.props?.history?.push("/dashboard/banks");
          }}
          refreshDashBoard={(isRefresh) => this.refreshDashBoard(isRefresh)}
        />
      )}
      <div>
      <Drawer
      className='mobile-menu'
      
        placement={this.state.placement}
        closable={false}
        onClose={this.onClose}
        open={this.state.open}
        key={this.state.placement}
      >
        <ul>
        {window.location.pathname.indexOf("/dashboard") > -1 && 
          <li  onClick={()=>window.open(`${process.env.REACT_App_EXCHANGE_URL}/cockpit`,"_self")}><Link> Back To  SuisseBase Dashboard</Link></li>}
               <li 
              onClick={() => this.routeToDashboard("dashboard")}
              className={`${window.location.pathname.includes("/dashboard/banks") && "list-item header-send text-white" || "list-item header-send"}`}>
         
                <Link>Dashboard</Link>
              </li>
              {this.state?.marketplacepermission && (
              <li
              onClick={() => this.routeToDashboard("Send", { key: "dashboard_marketplace", path: "/dashboard/marketplace" })}
              className={`${window.location.pathname.includes("/marketplace") && "list-item header-send text-white" || "list-item header-send"}`}>
         
                <Link>Marketplace</Link>
              </li>)}
        </ul>
       
      </Drawer>
      </div>
              </>
    );
  }
}

const connectStateToProps = ({ userConfig,menuItems }) => {
  return {
     userConfig: userConfig.userProfileInfo ,
     sendPermissions: menuItems?.featurePermissions?.send,
     marketPlace:menuItems?.featurePermissions?.dashboard_marketplace
  };
};
export default connect(connectStateToProps)(withRouter(AppHeader));
