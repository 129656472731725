import React, { useEffect, useState, useReducer } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Form from "antd/lib/form";
import List from "antd/lib/list";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Alert from "antd/lib/alert";
import DatePicker from "antd/lib/date-picker";
import Menu from "antd/lib/menu";
import Modal from "antd/lib/modal";
import Upload from "antd/lib/upload";
import Dropdown from "antd/lib/dropdown";
import Space from "antd/lib/space";
import Tooltip from "antd/lib/tooltip";
import Image from "antd/lib/image";
import { useForm } from "antd/lib/form/Form";
import { downloadConfirmation, previewData, isErrorDispaly, BankLu, saveOpenpydStatus, currencyLu, saveOpenpydDeposit } from "../../api/apiCalls";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Loader from "../shared/loader";
import GridList from "../grid.component";
import moment from "moment";
import Recive from "../recive.component";
import SuissebaseDigitalWallet from "../suissebaseDigitalWallet";
import { onExcellExport } from "../grid.component/subscribir";
import OnthegoFundTransfer from "../onthego.transfer";
import NumberFormat from "react-number-format";
import TransactionSlips from "./transactionSlips";
import Select from "antd/lib/select";
import { getBankData } from '../../reducers/configReduser';
import { store } from "../../store";
import openPaydReducer from '../../reducers/openpaydreducer';
import zoho from '../../assets/images/zohobook.svg';
import zohoInvoice from '../../assets/images/zoho-invoice.png';
import xero from '../../assets/images/xero-logo-new.svg';
import ethscanner from '../../assets/images/logo-etherscan-new.svg';
import tronscanner from '../../assets/images/tronscan.svg';
import metamask from '../../assets/images/metamask-new.svg';
import tronlink from '../../assets/images/tronlink.png';
import trustwallet from '../../assets/images/trustwallet.svg';
import tangem from '../../assets/images/tangem-new.svg';
import coinledger from '../../assets/images/coinledger.svg';
import blockchair from '../../assets/images/blockchair.svg';
import SbCard from './sbcard'
import Input from "antd/lib/input";
import { type } from "@testing-library/user-event/dist/type";

const { Title, Text } = Typography;

const AccountView = (props) => {
  const gridRef = React.createRef();
  const [form] = useForm();

  const [searchObj, setSearchObj] = useState({ fromdate: "", todate: ""});
  const [searchgridObj, setSearchgridObj] = useState({ fromdate: "", todate: "" });
  const [currencies, setCurrencies] = useState([]);
  const [amount, setAmount] = useState(null)
  const { Option } = Select;
  const { menuItems: { featurePermissions } } = store?.getState();
  const selectedCurrency = window?.location.pathname.split('/')[3]
  const [state, dispatch] = useReducer(openPaydReducer, ({
    isTransactionError: null, isStatusModal: false, selectedobj: {}, isBtnLoader: false,
    selecedState: null, bankLuData: [], provider: null, isError: null, sendPermission: null, receivePermission: null, transferpermission: null,
    receiveEnable: false, isModalVisible: false, isCheck: false, digitalWalletEnable: false, error: "", sendEnable: false, accountData: [], seelctedAccount: {},
    isRefreshDashBoard: false, showModal: false, viewData: null, selectedCoin: {}, load: false, downloadLoader: false, isDepositModal: false, depositData: {},
    showIframe: false, iframeUrl: null, btnLoading: false, isDepositError: null,currency:"All"
  }));
  useEffect(() => {
    props?.getBankDetails(props.match?.params?.isBank)
    dispatch({ type: "provider", payload: props.match?.params?.isBank })
    getBankLu();
    if (props?.action == "transfer") {
      dispatch({ type: "sendEnable", payload: true })
    } else if (props?.action == "receive") {
      dispatch({ type: "receiveEnable", payload: true })
    }
    else if (props?.action == "digitalwallet") {
      dispatch({ type: "digitalWalletEnable", payload: true })
    }
  }, [props.match?.params?.isBank]);
  useEffect(() => {
    if (state?.isRefreshDashBoard) {
      props?.getBankDetails(props.match?.params?.isBank, (callback) => {
        if (callback) {
          dispatch({ type: "error", payload: callback.error })
        }
      })
      dispatch({ type: "isRefreshDashBoard", payload: false })
    }
  }, [state?.isRefreshDashBoard]);
  useEffect(() => {
    dispatch({ type: "accountData", payload: props?.bankDetailsData?.data })
    dispatch({ type: "sendPermission", payload: featurePermissions?.send?.actions?.find(action => action.permissionName === "View")?.values })
    dispatch({ type: "receivePermission", payload: featurePermissions?.receive?.actions?.find(action => action.permissionName === "View")?.values })
    dispatch({ type: "transferpermission", payload: featurePermissions?.transfer?.actions?.find(action => action.permissionName === "Transfer")?.values })
    dispatch({ type: "isCheck", payload: false })
    setSearchgridObj({
      fromdate: '',
      todate: '',
    });
    getCurrencyLu()
    dispatch({ type: "currency", payload:selectedCurrency})

  }, [props?.bankDetailsData?.data, featurePermissions?.receive?.actions?.find(action => action.permissionName === "View")?.values, featurePermissions?.send?.actions?.find(action => action.permissionName === "View")?.values])
  useEffect(() => {
    gridRef.current?.refreshGrid();
  }, [searchgridObj, state?.provider, state?.isCheck]);

  

  useEffect(() => {
    if (state.depositData) {
      form.setFieldsValue(state.depositData);
    }
  }, [state.depositData]);


  const refreshDashBoard = (isRefresh) => {
    dispatch({ type: "isRefreshDashBoard", payload: isRefresh })
  };

  const getCurrencyLu = async () => {
    let response = await currencyLu()
    if (response.ok) {
      setCurrencies(response.data)
    } else {
      dispatch({ type: "error", payload: isErrorDispaly(response) })

    }
  }
  const handleAmountChange = (val) => {
    setAmount(val?.currentTarget?.value)
  }

  const handleChange = (val, type) => {
    let formatDate = val ? moment(val).format("YYYY-MM-DD") : "";
    if (type === "fromdate" || type === "todate") {
      searchObj[type] = formatDate;
      setSearchObj(searchObj);
      if (!formatDate) {
        searchgridObj[type] = "";
        setSearchgridObj(searchgridObj);
      }
    } else {
      searchObj[type] = val;
      setSearchObj(searchObj);
      if (!val) {
        searchgridObj[type] = "";
        setSearchgridObj(searchgridObj);
      }
    }
  };
  const handleSearch = () => {
    dispatch({ type: "isTransactionError", payload: null })
    if (searchObj.todate == "" && searchObj.fromdate == "") {
      gridRef.current.refreshGrid();
    } else if (searchObj.todate === "") {
      dispatch({ type: "isTransactionError", payload: "Please select the To Date" });
    } else if (searchObj.fromdate === "") {
      dispatch({ type: "isTransactionError", payload: "Please select the From Date" });
    } else if (searchObj.todate < searchObj.fromdate) {
      dispatch({ type: "isTransactionError", payload: "To Date must be greater than or equal to From Date." });
    } else {
      dispatch({ type: "isTransactionError", payload: null });
      setSearchgridObj({
        fromdate: searchObj.fromdate,
        todate: searchObj.todate,
      });
    }
  };
  const transactionModal = async (prop) => {
    dispatch({ type: "showModal", payload: true });
    dispatch({ type: "load", payload: true });
    let response = await previewData(
      prop?.dataItem?.id,
      prop?.dataItem?.transactionType
    );
    if (response.ok) {
      dispatch({ type: "load", payload: false });
      dispatch({ type: "error", payload: null })
      dispatch({ type: "viewData", payload: response.data })
      dispatch({ type: "selectedCoin", payload: prop?.dataItem })
    } else {
      dispatch({ type: "error", payload: isErrorDispaly(response) })
      dispatch({ type: "load", payload: false });
    }
  };

  const handleModalCancel = () => {
    dispatch({ type: "showModal", payload: false });
  };
  const gridColumns = [
    {
      field: "transactionType",
      title: "Transaction Type",
      filter: true,
      width: 200,
      customCell: (data) => (
        <td className="d-flex justify-content">
          <div
            className="gridLink c-pointer"
            onClick={() => transactionModal(data)}
          >
            {data?.dataItem?.transactionType}
          </div>
        </td>
      )
    },
    {
      field: "createdAt",
      title: "Date",
      filter: true,
      isShowTime: true,
      filterType: "date",
      width: 250
    },
    { field: "currency", title: "Currency", filter: true, width: 200 },
    { field: "details", title: "Details", filter: true, width: 200 },
    {
      field: "deposit",
      title: "Deposit",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "withdrawal",
      title: "Withdraw",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "balances",
      title: "Available Balance",
      filter: true,
      width: 200,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    { field: "bankName", title: "Bank Name", filter: true, width: 200 },
    {
      field: "accountNumber",
      title: "Bank Account Number/IBAN",
      filter: true,
      width: 280,
      footerCell: true,
      dataType: "number",
      filterType: "numeric"
    },
    {
      field: "bankTransactionStatus",
      title: "Status",
      filter: true,
      width: 200
    },
  ];

  const sendFunds = (item) => {
    props.history.push(`/dashboard/transfer/${item?.currency}/${item?.id}/${item?.provider}`)
    dispatch({ type: "seelctedAccount", payload: item })
    dispatch({ type: "sendEnable", payload: true })
    dispatch({ type: "receiveEnable", payload: false })
  };

  const receiveFunds = (item) => {
    dispatch({ type: "seelctedAccount", payload: item })
    dispatch({ type: "receiveEnable", payload: true })
    dispatch({ type: "digitalWalletEnable", payload: false })
  };

  const onClose = () => {
    dispatch({ type: "receiveEnable", payload: false })
    dispatch({ type: "digitalWalletEnable", payload: false })
    if (props?.match?.url) {
      props?.history?.push(props?.match?.url);
    } else {
      props?.history?.push("/dashboard");
    }
  };

  const onCloseReceive = () => {
    dispatch({ type: "receiveEnable", payload: false })
    dispatch({ type: "digitalWalletEnable", payload: false })
  };

  const handleOk = () => {
    dispatch({ type: "isModalVisible", payload: false })
  };

  const handleCancel = () => {
    dispatch({ type: "isModalVisible", payload: false })
  };
  const suissebaseDigitalWallet = (item) => {
    dispatch({ type: "digitalWalletEnable", payload: true })
    dispatch({ type: "seelctedAccount", payload: item })
    dispatch({ type: "receiveEnable", payload: false })
  };
  const isStatusModalOpen = (val, state) => {
    dispatch({ type: "isError", payload: null })
    dispatch({ type: "isStatusModal", payload: true })
    dispatch({ type: "selecedState", payload: state })
    dispatch({ type: "selectedobj", payload: val })
  }
  const menuBar = (item) => (
    <Menu>
      <ul className="pl-0 drpdwn-list">
        {/* {<li>
          <Link to={"/internaltransfer"} value={3} className="c-pointer">
            <span>Internal Transfer</span>
          </Link>
        </li>} */}
        {/* selectedFeaturePermission?.Transfer */}

        {state?.transferpermission && (
          <li onClick={() => {
            if (item?.accountStatus?.toLowerCase() !== "freeze") {
              suissebaseDigitalWallet(item);
            }
          }} className="c-notallowed" disabled={item?.accountStatus?.toLowerCase() === "freeze" ? true : false}>
            <Link value={3} className={item?.accountStatus?.toLowerCase() === "freeze" ? "c-notallowed" : "c-pointer"}>
              <span> Transfer To SuisseBase Digital Wallet</span>
            </Link>
          </li>
        )}
        <li onClick={() => handleDownload(item)}>
          <Link value={4} className="c-pointer">
            <div className="account-loader">
              <span> Account Confirmation Letter Download</span> {state?.downloadLoader && <Loader />}</div>
          </Link>
        </li>
        {item?.provider?.toLowerCase() == "openpayd" && <><li onClick={() => isStatusModalOpen(item, "CLOSE")}>
          <Link value={4} className="c-pointer">
            <span>Close Account</span>
          </Link>
        </li>
        </>}
      </ul>
    </Menu>
  );

  const checkBox = (e) => {
    dispatch({ type: "isCheck", payload: e.target.checked })
    gridRef.current?.refreshGrid();
  };

  const onMenuItemClick = (item, provider) => {
    props.history.push(`/createAccount/${item}/${props?.userConfig?.isBusiness}/${provider}`);
  };

  const handleDownload = async (item) => {
    dispatch({ type: "downloadLoader", payload: true })
    let response = await downloadConfirmation(
      item.id,
      item.currency,
      item.accountStatus,
      item.provider
    );
    if (response.ok) {
      dispatch({ type: "error", payload: null })
      dispatch({ type: "downloadLoader", payload: false })
      const pdfUrl = response.data;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.target = '_blank';
      link.download = 'file.pdf';
      link.click();
      message.success({
        content: "Downloaded successfully",
        className: "custom-msg",
        duration: 3
      });
    } else {
      dispatch({ type: "downloadLoader", payload: false })
      dispatch({ type: "error", payload: isErrorDispaly(response) })

    }
  };
  const savepaydStatus = async () => {
    dispatch({ type: "isBtnLoader", payload: true })
    let obj = {
      id: state?.selectedobj?.id,
      state: state?.selecedState,
      ModifiedBy: props?.userConfig?.userName,
    }
    let res = await saveOpenpydStatus(obj);
    if (res.ok) {
      dispatch({ type: "isClosedAccount", payload: true });
      dispatch({ type: "isBtnLoader", payload: false });
    } else {
      dispatch({ type: "isError", payload: isErrorDispaly(res) })
      dispatch({ type: "isBtnLoader", payload: false })
    }
  }

  const getBankLu = async () => {
    let res = await BankLu();
    if (res.ok) {
      dispatch({ type: "bankLuData", payload: res.data })
    } else {
      dispatch({ type: "isError", payload: isErrorDispaly(res) })
    }
  }
  const handleBankLu = (val) => {
    dispatch({ type: "provider", payload: val })
  }
  const gotoMarketPlace = (item) => {
    if (item?.provider?.toLowerCase() == "openpayd") {
      if (!window.location.pathname.includes('createAccount')) {
        props?.history?.push(`/dashboard/banks/${item?.currency}/${item?.id}/${item?.provider?.toLowerCase()}`);
      }
    }
  }
  const closeModalPopUp = () => {
    dispatch({ type: "isStatusModal", payload: false });
    dispatch({ type: "isClosedAccount", payload: false });
  }

  const onHandleDeposit = (item) => {
    dispatch({ type: "isDepositModal", payload: true });
    dispatch({ type: "showIframe", payload: false });
    dispatch({ type: "depositData", payload: item });
  };

  const saveDeposit = async (values) => {
    const amount = typeof values.amount === "string" ? parseFloat(values.amount.replace(/,/g, '')) : parseFloat(values.amount);

    if (amount < 50) {
      dispatch({ type: "isDepositError", payload: "Amount must be greater than or equal to 50." });
    } else if (amount > 500000) {
      dispatch({ type: "isDepositError", payload: "The amount exceeds the maximum limit of 500,000." });
    }

    else {
      dispatch({ type: "btnLoading", payload: true });
      dispatch({ type: "isDepositError", payload: null });

      let obj = {
        amount: values?.amount,
        currency: values?.currency,
        customerId: props?.userConfig?.id,
      };

      let response = await saveOpenpydDeposit(obj);
      if (response.ok) {
        dispatch({ type: "showIframe", payload: true });
        dispatch({ type: "iframeUrl", payload: response?.data?.uri });
        dispatch({ type: "isDepositModal", payload: false });
        dispatch({ type: "btnLoading", payload: false });
        dispatch({ type: "isDepositError", payload: null });
        //form.setFieldsValue({ amount: null });
        form.resetFields();


      } else {
        dispatch({ type: "btnLoading", payload: false });
        dispatch({ type: "isDepositError", payload: isErrorDispaly(response) })

      }
    }
  }

  const CloseDepositModal = () => {
    dispatch({ type: "isDepositModal", payload: false });
    dispatch({ type: "isDepositError", payload: null })
    form.resetFields();

  };



  const sixMaxAllowedDecimals = (values) => {
    if (!values.value) return true;
    const { floatValue } = values;
    return floatValue <= 999999.99;
  }
  const currencyHandleChange = (value) => {
    dispatch({ type: "currency", payload: value || selectedCurrency})
  };
  return (
    <>
      {state?.error !== undefined && state?.error !== null && state?.error !== "" && (
        <div style={{ width: "100%" }}>
          <Alert
            className="w-100 mb-16"
            type="error"
            description={state?.error}
            showIcon
          />
        </div>
      )}
      {props.bankDetailsData?.loading ? (
        <Loader />
      ) : (

        <div>
          <div className="dashboard-cards">


            {props?.match?.url.includes("/marketplace") && <><div className='text-align-inside'>
              <div className='megamenu-title'>Accounts/Finance</div>
              <ul className="appmenu-list">
                <li className="">
                  <a href='https://www.zoho.com/in/books/' target='_blank'>
                    <img src={zoho} alt="" />
                    <span className='logo-text c-pointer'>Books</span></a>
                </li>
                <li className="">
                  <a href='https://www.zoho.com/invoice/free-invoice-generator.html' target='_blank'>
                    <img src={zohoInvoice} alt="" />
                    <span className='logo-text c-pointer'>Zoho Invoice</span></a>
                </li>
                <li className="">
                  <a href='https://www.xero.com/sg/signup/' target='_blank'>
                    <img src={xero} alt="" />
                    <span className='logo-text c-pointer'>Xero</span></a>
                </li>
                <li className="">
                  <a href='https://coinledger.io/' target='_blank'>
                    <img src={coinledger} alt="" />
                    <span className='logo-text c-pointer'>CoinLedger</span></a>
                </li>
              </ul>
            </div>
              <div className='text-align-inside'>
                <div className='megamenu-title'>Explorer</div>
                <ul className="appmenu-list">
                  <li className="">
                    <a href="https://etherscan.io/" target='_blank'> <img src={ethscanner} alt="" />
                      <span className='logo-text c-pointer'>ETH Scanner</span></a>
                  </li>
                  <li className="">
                    <a href='https://tronscan.org/' target='_blank'>
                      <img src={tronscanner} alt="" />
                      <span className='logo-text c-pointer'>Tron Scanner</span></a>
                  </li>
                  <li className="">
                    <a href='https://blockchair.com/' target='_blank'>
                      <img src={blockchair} alt="" />
                      <span className='logo-text c-pointer'>Blockchair</span></a>
                  </li>
                </ul>
              </div>
              <div className='text-align-inside'>
                <div className='megamenu-title'>SCW</div>
                <ul className="appmenu-list">
                  <li className="">
                    <a href='https://metamask.io/download/' target='_blank'>
                      <img src={metamask} alt="" />
                      <span className='logo-text c-pointer'>MetaMask</span></a>
                  </li>
                  <li className="">
                    <a href="https://www.tronlink.org/dlDetails/" target='_blank'>
                      <img src={tronlink} alt="" />
                      <span className='logo-text c-pointer'>Tron Link</span></a>
                  </li>
                  <li className="">
                    <a href='https://trustwallet.com/' target='_blank'>
                      <img src={trustwallet} alt="" />
                      <span className='logo-text c-pointer'>Trust Wallet</span></a>
                  </li>
                  <li className="">
                    <a href='https://tangem.com/en/pricing/' target='_blank'>
                      <img src={tangem} alt="" className='tangem-image' />
                      <span className='logo-text c-pointer'>Tangem</span></a>
                  </li>
                </ul>
              </div>


              <SbCard />
            </>}
            {props?.match?.url.includes("/dashboard") && !props?.match?.url.includes("/marketplace") &&
              <List
                itemLayout="horizontal"
                dataSource={state?.accountData} className="mobile-list marketplace-list-container custom-fund-buttons iban-list bg-dynamic"
                renderItem={(item) => {
                  if (item?.provider?.toLowerCase() !== "pyrros") {

                    return <List.Item className={`${props.match?.params?.isBank ? `$ marketplace-list mr-0` : item?.provider?.toLowerCase() == "openpayd" ? `marketplace-list mr-0 c-pointer` : `marketplace-list mr-0 bg-green`} ${props.match?.params?.isBank ? "bg-blue" : ""}`} onClick={() => gotoMarketPlace(item)}>
                      <div  >
                        <List.Item.Meta className="d-flex"
                          avatar={
                            <Image preview={false} src={item.imagePath} />
                          }

                          description={

                            <div>
                              <div className="d-flex justify-content">
                                <div className="fs-16 fw-600  text-white-30 l-height-normal">

                                  <div className="word-breakall mb-10"><span>{item?.provider?.toLowerCase() == "openpayd" && "OpenPayd" || item?.provider?.toLowerCase() == "pyrros" && "Pyrros" || "--"}{" "} {item?.currency} {' '} {item?.provider && "IBAN"}  </span> {item?.provider?.toLowerCase() == "openpayd" && <span className="coming-soon text-right"> <span className="sepa-bg">SEPA Only</span> </span>}</div>
                                  <div className="mt-4 word-breakall minh-38">{item?.accountNumber}</div>
                                  {(item?.accountStatus?.toLowerCase() == "freeze" || item?.accountStatus?.toLowerCase() == "unfreeze" || item?.accountStatus?.toLowerCase() == "closed") && <div className="mt-16 card-status"><span className="text-white cust-badge-design">{item?.accountStatus?.toLowerCase() == "freeze" && "Freezed" || item?.accountStatus?.toLowerCase() == "closed" && "Closed"}</span></div>}
                                  {item?.accountStatus?.toLowerCase() == "freeze" || item?.accountStatus?.toLowerCase() == "unfreeze" || !item?.accountStatus?.toLowerCase() == "closed" && <div className="min-h-value"></div>}
                                </div>
                                {(item?.accountStatus?.toLowerCase() == "approved" || item?.accountStatus?.toLowerCase() == "freeze") && props.match?.params?.isBank && (<div className="crypto-btns menu-dots">
                                  <Dropdown
                                    overlay={menuBar(item)}
                                    trigger={["click"]}
                                    placement="bottomCenter"
                                    arrow
                                    overlayClassName="secureDropdown depwith-drpdown"
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        <span class="icon md menu-bar ml-4 p-relative"></span>
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </div>)}

                                {item?.provider?.toLowerCase() !== "openpayd" && <p className="coming-soon text-right"> <span>Coming Soon</span> </p>}
                              </div>
                              <div className="d-flex mt-8  flex-wrap card-openpaid">
                                {item?.provider?.toLowerCase() == "openpayd" && <> {item?.accountStatus?.toLowerCase() ==
                                  "approved" && (
                                    <div className="mr-16">
                                      <Text className="case-lbl">
                                        Available{" "}
                                      </Text>
                                      <div className="case-val d-flex">
                                        {item.availableBalance !== null ? (
                                          <NumberFormat
                                            value={item.availableBalance}
                                            className="text-white-30 fw-600 mr-4"
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={
                                              item.currency == "USD" ? "$" : "€"
                                            }
                                            renderText={(value, props) => (
                                              <p {...props}>{value}</p>
                                            )}
                                          />
                                        ) : (
                                          "€0"
                                        )}{" "}

                                      </div>
                                    </div>
                                  )}
                                  {item?.accountStatus?.toLowerCase() ==
                                    "approved" && (
                                      <div>
                                        <Text className="case-lbl">
                                          Total Balance{" "}
                                        </Text>
                                        <div className="case-val d-flex">
                                          {item.totalBalance !== null ? (
                                            <NumberFormat
                                              value={item.totalBalance}
                                              className="text-white-30 fw-600 mr-4"
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={
                                                item.currency == "USD" ? "$" : "€"
                                              }
                                              renderText={(value, props) => (
                                                <p {...props}>{value}</p>
                                              )}
                                            />
                                          ) : (
                                            "€0"
                                          )}{" "}

                                        </div>
                                      </div>
                                    )}</>}
                              </div>

                            </div>
                          }
                        />
                        {item?.accountStatus?.toLowerCase() ==
                          "approved" && (
                            <>
                              {" "}
                              <div className="accountview-card">
                                <Tooltip title={"Account confirmation"}>

                                </Tooltip>
                              </div>
                            </>
                          )}
                      </div>
                      {item?.provider?.toLowerCase() == "openpayd" && <>{item.isAccountExist ? (
                        <>
                          {item?.accountStatus?.toLowerCase() ==
                            "approved" && (

                              <div className="mt-46 card-btns">
                                {state?.sendPermission && props.match?.params?.isBank && (
                                  <Button
                                    type="primary"
                                    className="custom-btn prime text-purewhite"
                                    onClick={() => sendFunds(item)}
                                    disabled={
                                      !(
                                        item.availableBalance &&
                                        item.availableBalance > 0
                                      )
                                    }
                                  >
                                    {" "}
                                    Send
                                  </Button>)}
                                {state?.receivePermission && props.match?.params?.isBank && (
                                  <Button
                                    type="primary"
                                    className="custom-btn sec"
                                    htmlType="submit"
                                    onClick={() => receiveFunds(item)}
                                  >
                                    Receive{" "}
                                  </Button>)}
                                {props.match?.params?.isBank && (
                                  <Button
                                    type="primary"
                                    className="custom-btn sec"
                                    htmlType="submit"
                                    onClick={() => onHandleDeposit(item)}
                                  >
                                    Deposit{" "}
                                  </Button>)}
                                  <div className='currency-section' ><img src={item?.currencyImage} width={40} className="currency-logo" /></div> 
                              </div>

                            )}

                          
                          {(item?.accountStatus?.toLowerCase() !== "approved" ) && (

                            <div className="card-btns mt-46">
                              {(

                                <Button
                                  content="Pending"
                                  type="primary"
                                  className="custom-btn prime pending-cursors text-white text-center py-8"
                                  onClick={(e) => { e.stopPropagation() }
                                  }
                                >
                                  {item?.accountStatus?.toLowerCase() ==='pending' ? "Pending": item?.accountStatus?.toLowerCase() ==='rejected' && "Rejected"}
                                </Button>)}
                                <div className='currency-section' ><img src={item?.currencyImage} width={40} className="currency-logo" /></div> 
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="card-btns mt-46">
                          {props.dashboardPermissions?.createaccount && item?.accountStatus?.toLowerCase() == null && (
                            <Button
                              content="Pending"
                              type="primary"
                              className="custom-btn prime "
                              onClick={(e) => {
                                e.stopPropagation()
                                onMenuItemClick(item.currency, item?.provider)
                              }
                              }
                            >
                              Create Account
                            </Button>
                          )}
                          {item?.accountStatus?.toLowerCase() ==
                            "approved" && !props.match.params.isBank && (
                              <Button
                                content="View Account"
                                type="primary"
                                className="custom-btn prime "

                                onClick={() => gotoMarketPlace(item)}
                              >
                                View Account
                              </Button>
                            )}
                          {item?.accountStatus?.toLowerCase() !==
                            "approved" && !props.match.params.isBank && item?.accountStatus?.toLowerCase() !== "freeze" && item?.accountStatus?.toLowerCase() !== "closed" && (item?.accountStatus?.toLowerCase() === "pending" || item?.accountStatus?.toLowerCase() === "rejected") && (

                              <Button
                                content="Pending"
                                type="primary"
                                className="custom-btn prime "
                                disabled={item?.accountStatus?.toLowerCase() === "pending" || item?.accountStatus?.toLowerCase() === "rejected"}
                              >
                                {item?.accountStatus}
                              </Button>
                               
                            )}
                            <div className='currency-section' ><img src={item?.currencyImage} width={40} className="currency-logo" /></div> 
                        </div>
                        
                      )}

                      </>}
                    </List.Item>
                  }
                }
                }
              />
            }


          </div>

          <div className="">
            {props.match?.params?.isBank && <div>
              <Row className="d-flex justify-content align-center mb-4">
                <Title className="db-titles mt-24 mb-16">
                  {state?.isCheck && "Pending Transactions"}
                  {!state?.isCheck && "Transaction History"}
                </Title>
              </Row>
              {state?.isTransactionError !== undefined && state?.isTransactionError !== null && state?.isTransactionError !== "" && (
                <div style={{ width: "100%" }} className="mt-24">
                  <Alert
                    className="w-100 mb-16"
                    type="error"
                    description={state?.isTransactionError}
                    showIcon
                  />
                </div>
              )}
              <Form className="form form-bg search-bg">
                <Row gutter={24} style={{ rowGap: '0px', margin: '0' }} className="filter-content">
                  {!props.match?.params?.isBank && <Col xs={24} sm={24} md={24} xl={6} xxl={6} className="mb-10">
                    <Form.Item
                      className="custom-forminput custom-label mb-0"
                      name="payeeAccountCountry"
                      label={
                        'Select Your Bank'
                      }
                    >
                      <Select
                        showSearch
                        placeholder={"All"}
                        className="cust-input select-crypto cust-adon bank-select c-pointer"
                        dropdownClassName="select-drpdwn"
                        bordered={false}
                        onChange={(item) => handleBankLu(item)}
                      >
                        {state?.bankLuData.map((item, indx) => (
                          <Option key={indx} value={item.name}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>}

                  <Col xs={24} sm={24} md={24} xl={6} xxl={6} >
                    <Form.Item
                      name="fromdate"
                      className="input-label mb-0 ml-0 c-pointer"
                      label="From Date"
                    >
                      <DatePicker
                        className="cust-input bgwhite custom-datepiker cust-date-width newcust-bg"
                        placeholder="Select From Date"
                        format={"DD/MM/YYYY"}
                        onChange={(e) => handleChange(e, "fromdate")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={6} xxl={6} >
                    <Form.Item
                      name="todate"
                      className="input-label mb-0 ml-0 c-pointer"
                      label="To Date"
                    >
                      <DatePicker
                        className="cust-input  bgwhite custom-datepiker cust-date-width newcust-bg"
                        placeholder="Select To Date"
                        format={"DD/MM/YYYY"}
                        onChange={(e) => handleChange(e, "todate")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                    <Form.Item
                      name="currency"
                      className="input-label mb-0 ml-0 c-pointer"
                      label="Currency"
                    >
                      <Select
                        className="cust-input bgwhite custom-datepiker cust-date-width newcust-bg"
                        placeholder="Select Currency"
                        value={state?.currency || selectedCurrency} 
                        defaultValue={selectedCurrency}
                        onChange={currencyHandleChange}
                      >
                        {currencies.map((items) => (
                          <Select.Option key={items.id} value={items?.currency}>
                            {items?.currency}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} xl={6} xxl={6} className="px-8">
                    <Form.Item>
                      <Button
                        className="pop-btn search-btn mt-36"
                        type="submit"
                        htmlType="submit"
                        onClick={() => handleSearch()}
                      >
                        Search {""}
                        <span class="icon md search-angle"></span>
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={18}
                    sm={18}
                    md={24}
                    xl={4}
                    xxl={4}
                    className="pending-col pb-8"
                  >
                    <label
                      className="text-center custom-checkbox"
                      style={{ color: "white" }}
                    >
                      <input
                        name="check"
                        type="checkbox"
                        checked={state?.isCheck}
                        onChange={checkBox}
                        className="grid_check_box"
                      />
                      <span> </span>
                    </label><span className="pending-transactions text-white ml-8">Pending Transactions</span>
                  </Col>
                </Row>
              </Form>
              <div className="text-right export-pdf secureDropdown">
                <Tooltip placement="top" title={"Export to Excel"}>
                  <button className="c-pointer pop-btn" onClick={() => {
                    onExcellExport();
                  }}>
                    <span>Download Transactions</span>
                    <span
                      className="icon xl mr-0 excelexport"

                    ></span></button>
                </Tooltip>
              </div>

              <div
                className="cust-list custom-grd-view"
                style={{ clear: "both" }}
              >
                <GridList className="cust-kendo-grid"
                  showActionBar={true}
                  additionalParams={searchgridObj}
                  showExcelExport={true}
                  url={
                    process.env.REACT_APP_GRID_API +
                    `${state?.provider ? (`Bank/AllBanksTransactionsuser/${state?.provider ? state?.provider : ""}/${state?.isCheck ? "pending" : "All"}/${state?.currency}`) : (`Bank/AllBanksTransactions/${state?.isCheck ? "pending" : "All"}`)}`
                  }
                  columns={gridColumns}
                  ref={gridRef}
                  excelFileName={"Transaction History"}
                />
              </div>
            </div>}

            <Modal
              title="Upload Documents :"
              visible={state?.isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <textarea>Upload Here</textarea>
              <Upload
                accept=".pdf,.jpg,.jpeg,.png, .PDF, .JPG, .JPEG, .PNG"
                showUploadList={false}
              >
                <span className="icon md attach mr-16 c-pointer" />
              </Upload>
            </Modal>

            {state?.receiveEnable && (
              <Recive
                onreciveClose={() => onCloseReceive()}
                reciveId={state?.seelctedAccount.id || props.id}
                currency={state?.seelctedAccount.currency || props.currency}
                action={props.action}
              />
            )}


            {state?.digitalWalletEnable && (
              <SuissebaseDigitalWallet
                currency={state?.seelctedAccount.currency || props.currency}
                selectedBank={state?.seelctedAccount}
                action={props.action}
                id={state?.seelctedAccount.id || props.id}
                onDigitalWalletClose={() => onClose()}
                refreshDashBoard={(isRefresh) => refreshDashBoard(isRefresh)}
              />
            )}
          </div>
        </div>
      )}
      <Modal
        title={`${!state?.isClosedAccount ? `Confirm ${state?.selecedState?.toLowerCase()}?` : "Close account request"}`}
        visible={state?.isStatusModal}
        className="crypto-list footer-border"
        destroyOnClose
        closeIcon={
          <Tooltip title="Close">
            <span
              className="icon close md x c-pointer"
              onClick={() => closeModalPopUp()}
            />
          </Tooltip>
        }
        footer={<>
          <div className="cust-pop-up-btn crypto-pop">
            <Button
              type="primary"
              className="ant-btn ant-btn-primary custom-btn sec ml-16"
              onClick={() => closeModalPopUp()}
            >
              {state?.isClosedAccount && "Close" || "No"}
            </Button>
            {!state?.isClosedAccount && <Button
              type="submit"
              className="ant-btn ant-btn-primary custom-btn prime"
              onClick={() => savepaydStatus()}
              loading={state?.isBtnLoader}
            >
              Yes
            </Button>}</div></>
        }>
        {state?.isError !== undefined && state?.isError !== null && state?.isError !== "" && (
          <div style={{ width: "100%" }}>
            <Alert
              className="w-100 mb-16"
              type="error"
              description={state?.isError}
              showIcon
            />
          </div>
        )}
        {state?.isClosedAccount && <p>{`You are requesting to close your Personal ${window.location.pathname.split('/')[3]} Account. You will receive an email notification when it is closed.`}</p>}
        {!state?.isClosedAccount && <p>Do you really want to {state?.selecedState?.toLowerCase()} the account ?</p>}
      </Modal>

      {state?.isDepositModal &&
        <Modal
          title={`Deposit via Open Banking`}
          visible={state?.isDepositModal}
          className="crypto-list footer-border deposit-banking"
          destroyOnClose
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon close md x c-pointer"
                onClick={() => CloseDepositModal()}
              />
            </Tooltip>
          }
          footer={null}
        >
          <Form
            form={form}
            initialValues={state?.depositData}
            onFinish={saveDeposit}
          >
            {state?.isDepositError !== undefined && state?.isDepositError !== null && state?.isDepositError !== "" && (
              <div style={{ width: "100%" }}>
                <Alert
                  className="w-100 mb-16"
                  type="error"
                  description={state?.isDepositError}
                  showIcon
                />
              </div>
            )}
            <Form.Item name="iban" className="mb-0">
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="accountNumber"
                    required
                    rules={[
                      { required: true, message: "Is required" },
                      { whitespace: true, message: "Is required" },
                    ]}
                    label={'OpenPayd IBAN'}
                  >
                    <Input
                      className="cust-input"
                      maxLength="20"
                      placeholder={"OpenPayd IBAN"}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="currency"
                    required
                    rules={[{ required: true, message: "Is required" }, { whitespace: true }]}
                    label={'Currency'}
                  >

                    <Input
                      className="cust-input"
                      maxLength="20"
                      placeholder={"Enter Currency"}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>

                  <Form.Item
                    className="custom-forminput custom-label mb-0"
                    name="amount"
                    label="Amount"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Is required',
                      },
                      {
                        validator: (_, value) => {
                          const reg = /.*[0-9].*/g;
                          if (value && !reg.test(value)) {
                            return Promise.reject("Invalid amount");
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <NumberFormat
                      customInput={Input}
                      className="cust-input mb-0"
                      placeholder={"Enter Amount"}
                      decimalScale={2}
                      displayType="input"
                      allowNegative={false}
                      thousandSeparator={","}
                      isAllowed={sixMaxAllowedDecimals}
                      onValueChange={(e) => handleAmountChange(e)}

                    />

                  </Form.Item></Col>
              </Row>
            </Form.Item>


            <Form.Item className="text-right mb-0">
              <Button
                type="primary"
                className="cust-cancel-btn modal-cancel"

                onClick={() => CloseDepositModal()}
              >
                Cancel
              </Button>

              <Button
                htmlType="submit"
                className="pop-btn mb-36"
                loading={state.btnLoading}
              >
                Proceed
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      }
      {state?.showIframe &&
        <Modal className="crypto-list footer-border deposit-banking"
          visible={state?.showIframe} destroyOnClose footer={null}
          closeIcon={
            <Tooltip title="Close">
              <span
                className="icon close md x c-pointer"
                onClick={() => dispatch({ type: "showIframe", payload: false })}
              />
            </Tooltip>
          }
        >
          <iframe
            src={state?.iframeUrl}
            style={{
              width: '100%',
              height: '470px',
              border: 'none',
              zIndex: 9999, marginTop: 24,
            }}
          ></iframe>
        </Modal>
      }


      {state?.sendEnable && (
        <OnthegoFundTransfer
          availableBalance={state?.seelctedAccount?.availableBalance || state?.accountData[0]?.availableBalance}
          selectedCurrency={state?.seelctedAccount.currency || props.currency}
          selectedAccountId={state?.seelctedAccount.id || props.id}
          action={props.action}
          onClose={() => {
            dispatch({ type: "sendEnable", payload: false })
          }}
          refreshDashBoard={(isRefresh) => refreshDashBoard(isRefresh)}
        />
      )}
      <TransactionSlips
        showModal={state?.showModal}
        handleCancel={handleModalCancel}
        viewData={state?.viewData}
        load={state?.load}
        downLoadButtonLoader={state?.downloadLoader}
        id={state?.selectedCoin.id}
      />


    </>
  );
};

const connectStateToProps = ({
  userConfig,
  transferData,
  digitalWalletData,
  bankDetailsData
}) => {
  return {
    userConfig: userConfig?.userProfileInfo,
    transferData,
    digitalWalletData, bankDetailsData: userConfig.bankDetailsData
  };
};
const connectDispatchToProps = dispatch => {
  return {
    getBankDetails: (id) => {
      dispatch(getBankData(id));
    }
  }
}

export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(AccountView));
