import React, { Component } from "react";
import { Row, Col, Form, Input, Typography, Button, Spin, Alert,Select} from 'antd';
import {getIBANData, isErrorDispaly} from "../../../api/apiCalls";
import {validateContentRule,validateFeild } from "../../../utils/custom.validator";
import {getReasonforTransferDetails} from '../api';
const {  Text } = Typography;
const { TextArea } = Input;
const {Option}=Select;
class PayeeBankDetails extends Component {
    state = {
        emailExist: false,
        bankDetailForm: React.createRef(),
        countries: [],
        states: [],
        isLoading: false,
        iBanDetals:null,
        IbanLoader:false,
        isValidIban:false,
        enteredIbanData: "",
        isShowValid: false,
        isValidateLoading: false,
        isValidCheck: false,
        ibannumber: null,
        errorMessage: null,
        reasonForTransferData:[],
        selectedReasonforTransfer:null,
    }
    componentDidMount(){
        this.setState({...this.state,selectedReasonforTransfer:null})
        this.getReasonForTransferData();
        if (this?.props?.selectedAddress?.id && this.props?.createPayeeObj) {
            if (this.props?.createPayeeObj?.payeeAccountModels[0]?.iban) {
                this.handleIban(this.props?.createPayeeObj?.payeeAccountModels[0].iban,"true")
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { transferType, domesticType } = this.props;
        if (
          transferType !== prevProps.transferType ||
          domesticType !== prevProps.domesticType
        ) {
          this.setState({ selectedReasonforTransfer: null });
        }
      }
    handleIban = async (ibannumber,isNext) => {
        this.setState({ ...this.state, enteredIbanData: ibannumber, isShowValid: false, iBanDetals: null,isValidateLoading:true});
        this.props.getIbandata(null);
        if (ibannumber?.length >= 10 && isNext) {
            this.setState({ ...this.state, iBanDetals: null, IbanLoader: true, isValidIban: true ,isValidateLoading:true})
            const ibanget = await getIBANData(ibannumber,this.props?.selectedBank)
            if (ibanget.ok) {
                if (ibanget.data && (ibanget.data?.routingNumber || ibanget.data?.bankName)) {
                    const bankdetails = { bankName: ibanget.data.bankName, bic: ibanget.data.routingNumber, bankBranch: ibanget.data.branch, country: ibanget.data.country, state: ibanget.data.state, city: ibanget.data.city, postalCode: ibanget.data.zipCode, line1: ibanget.data.bankAddress }
                    this.setState({ ...this.state, iBanDetals: bankdetails, enteredIbanData: ibannumber, IbanLoader: false, isValidIban: true, isShowValid: false, isValidateLoading: false, isValidCheck: true })
                    this.props.getIbandata(bankdetails,this.props?.selectedBank);
                    this.props.form.current?.setFieldsValue({ iban: ibannumber })
                } else {
                    this.setState({ ...this.state, IbanLoader: false, isValidIban: false, isValidateLoading: false })
                    const bankData = {bankName: "", routingNumber: ""}
                    this.props.getIbandata(bankData,this.props?.selectedBank);
                }
            } else {
                this.setState({ ...this.state, IbanLoader: false, isValidIban: false, isValidateLoading: false, errorMessage:isErrorDispaly(ibanget) })
                this.props.getIbandata(null);
            }
        } else {
            this.props.form.current?.setFieldsValue({ iban: ibannumber })
            this.setState({ ...this.state, enteredIbanData: ibannumber, isShowValid: false, IbanLoader: false, isValidIban: false, isValidateLoading: false })
        }
    }

    onIbanValidate = (e) => {
        if (e?.length >= 10) {
            if (e &&!/^[A-Za-z0-9]+$/.test(e)) {
                this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {},isValidateLoading: true});
                this.props.getIbandata(null);
                this.props.form?.current?.validateFields([["payeeAccountModels","iban"]], this.validateIbanType);
            }
            else {
                this.setState({ ...this.state, isValidCheck: true, isShowValid: false,isValidateLoading: true});
                this.handleIban(e, "true");
            }
        }
        else {
            this.setState({ ...this.state, isValidCheck: false, isShowValid: true, iBanValid: false, ibanDetails: {},isValidateLoading: true});
            this.props.getIbandata(null);
            this.props.form?.current?.validateFields([["payeeAccountModels","iban"]], this.validateIbanType)
        }
    }

    validateIbanType = (_, value) => {
        this.setState({ ...this.state, isValidateLoading: false, isShowValid: this.state.isShowValid?this.state.isShowValid:false});
        if ((!value&&this.state.isShowValid)||!value) {
            return Promise.reject("Is required");
        } else if ((!this.state.isValidIban&&this.state.isShowValid)|| value?.length < 10 || (value &&this.state.isShowValid&&
            !/^[A-Za-z0-9]+$/.test(value))) {
            this.props.getIbandata(null);
            return Promise.reject("Please input a valid IBAN");
        }
        else {
            return Promise.resolve();
        }
    };
    getReasonForTransferData=async()=>{
        let res = await getReasonforTransferDetails();
        if(res.ok){
            this.setState({...this.state,reasonForTransferData:res.data,errorMessage:null})
        }else{
            this.setState({...this.state,errorMessage: isErrorDispaly(res),})
           
        }
      }
    handleReasonTrnsfer=(e)=>{
        this.setState({...this.state,selectedReasonforTransfer:e})
        this.props.form.current.setFieldsValue({transferOthers:null})
    }
   
    renderAddress = (transferType) => {
        const _templates = {
            sepa: <>
            <>
           
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <div className="custom-btn-error validateiban-content">
                    <Form.Item
                        className="custom-forminput custom-label"
                        
                        name={["payeeAccountModels","iban"]}
                        label={"IBAN"}
                        required
                        rules={[
                            
                            {
                                validator:this.validateIbanType
                            }
                            
                        ]}
                        onChange={(e) => {
                            this.handleIban(e.target.value)
                        }}
                    >
                        <Input
                        maxLength={50}
                            className="cust-input ibanborder-field"
                            placeholder={"IBAN"}
                            addonAfter={<Button className={`px-24`} 
                            type="primary"
                            loading={this.state.isValidateLoading}
                            onClick={() => this.onIbanValidate(this.props?.form.current?.getFieldValue(["payeeAccountModels","iban"]))} 

                             >
                            <Text>Validate</Text>
                        </Button>}
                        />
                    </Form.Item></div>
                </Col>
                </>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>

                    <div className="box basic-info alert-info-custom mt-16 kpi-List">
                    <Spin spinning={this.state.IbanLoader}>
               
                    {(this.state.isValidIban && !this.props?.isAddTabCange)
                    && <Row>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    Bank Name
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.bankName||'---'}</Text></div>
                              </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    BIC
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.bic||'---'}</Text></div>
                              </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    Branch
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.bankBranch||'---'}</Text></div>
                             </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    Country
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.country||'---'}</Text></div>
                                 </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    State
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.state||'---'}</Text></div>
                             </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="mb-16">
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    City
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.city||'---'}</Text></div>
                            </div>
                            </Col>
                            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="kpi-divstyle">
                                <label className="kpi-label ">
                                    Zip
                                </label>
                                <div><Text className="kpi-val">{this.state.iBanDetals?.postalCode||'---'}</Text></div>
                              </div>
                            </Col>
                        </Row>}
                        {(!this.state.isValidIban || this.props?.isAddTabCange) && <span className="info-details">No bank details available</span>}
                        </Spin>
                    </div>

                </Col>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label"
                        name="reasonOfTransfer"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Is required",
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator: validateContentRule,
                            },
                        ]}
                        label={
                            "Reason For Transfer"
                        }
                    >
                        <Select
                            className="cust-input"
                            maxLength={100}
                            placeholder={"Reason For Transfer"}
                            optionFilterProp="children"
                            onChange={(e) => this.handleReasonTrnsfer(e)}
                        >
                            {this.state.reasonForTransferData?.map((item, idx) => (
                                <Option key={idx} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                        {this.state.selectedReasonforTransfer=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="transferOthers"
                            required
                            rules={[
                                {whitespace: true,
                                message: "Is required",
                                },
                                {
                                required: true,
                                message: "Is required",
                                },
                                {
                                validator: validateContentRule,
                            },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
                
          
            </>,
            swifttransfer: <>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","accountNumber"]}
                        label={"Account Number"}
                        
                        rules={[
                            {
                                required: true,
                               // whitespace: true,
                                message: "Is required",
                            },
                            {validator: (_, value) => {
                                if (
                                    value &&
                                    !/^[A-Za-z0-9]+$/.test(value)
                                ) {
                                    return Promise.reject(
                                        "Invalid Account Number"
                                    );
                                }
                                else if ( value && value.length > 50) {
                                    return Promise.reject("Invalid account number");
                                } 
                                else {
                                    return validateContentRule(_, value);
                                }
                            },
                            }
                        ]}
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Account Number"}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>
                {(this.props.domesticType === "international"||this.props.domesticType === "swifttransfer") &&<Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","swiftRouteBICNumber"]}
                        label={"Swift/BIC Code"}                       
                        rules={[
                            {
                                required: true,
                                //whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator: (_, value) => {
                                    if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid Swift / BIC Code"
                                        );
                                    }
                                    else if ( value && value.length > 50) {
                                        return Promise.reject("Invalid Swift / BIC code");
                                    } else {
                                        return validateContentRule(_, value);
                                    }
                                },
                            }
                        ]}
                    >
                        <Input
                            className="cust-input "
                            placeholder={"Swift/BIC Code"}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>}

                {this.props.domesticType === "domestic" && <Col xs={24} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","abaRoutingCode"]}
                        label="ABA Routing Code"
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    "Is required",
                            },{
                                validator: (_, value) => {
                                    if (
                                        value &&
                                        !/^[A-Za-z0-9]+$/.test(value)
                                    ) {
                                        return Promise.reject(
                                            "Invalid ABA Routing Code"
                                        );
                                    }else if(value && value?.length>50){
                                        return Promise.reject(`Invalid bank aba routing code`)
                                    }else {
                                        return validateContentRule(_, value);
                                    }
                                },
                            }
                        ]}
                    >
                        <Input
                            className="cust-input "
                            placeholder="ABA Routing Code"
                            maxLength="500"
                        />
                    </Form.Item>
                </Col>}
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","bankName"]}
                        label={"Bank Name"}
                        required
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message:
                                    "Is required",
                            },
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,validateContentRule:true})
                                }
                            },
                        ]}
                    >
                        <Input
                            className="cust-input"
                            placeholder={"Bank Name"}
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
              
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","line1"]}
                        required
                        rules={[
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: true,maxLength:250,validateContentRule:true,lableName:'bank address line 1'})
                                }
                            },
                            
                        ]}
                        
                        label={
                            "Bank Address 1"
                        }
                    >
                        <TextArea
                            placeholder={"Bank Address 1"}
                            className="cust-input cust-text-area address-book-cust"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            maxLength={1000}
                        ></TextArea>
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label mb-0"
                        name={["payeeAccountModels","line2"]}
                        rules={[
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator:(_,val)=>{
                                    return validateFeild({val:val,required: false,maxLength:50,validateContentRule:true,lableName:'bank address line 2'})
                                }
                            }                     

                        ]}
                        label={
                            "Bank Address 2"
                        }
                    >
                        <TextArea
                            placeholder={"Bank Address 2"}
                            className="cust-input cust-text-area address-book-cust"
                            autoSize={{ minRows: 1, maxRows: 2 }}
                            maxLength={1000}
                        ></TextArea>
                    </Form.Item>
                </Col> 
                <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item
                        className="custom-forminput custom-label"
                        name="reasonOfTransfer"
                        required
                        rules={[
                            {
                                required: true,
                                message: "Is required",
                            },
                            {
                                whitespace: true,
                                message: "Is required",
                            },
                            {
                                validator: validateContentRule,
                            },
                        ]}
                        label={
                            "Reason For Transfer"
                        }
                    >
                        <Select
                            className="cust-input"
                            maxLength={100}
                            placeholder={"Reason For Transfer"}
                            optionFilterProp="children"
                            onChange={(e) => this.handleReasonTrnsfer(e)}
                        >
                            {this.state.reasonForTransferData?.map((item, idx) => (
                                <Option key={idx} value={item.name}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                        {this.state.selectedReasonforTransfer=="Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
                            <Form.Item
                            className=" mb-8 text-white-50 custom-forminput custom-label pt-8 sc-error"
                            name="transferOthers"
                            required
                            rules={[
                                {whitespace: true,
                                message: "Is required",
                                },
                                {
                                required: true,
                                message: "Is required",
                                },
                                {
                                validator: validateContentRule,
                            },
                            ]}
                            >
                            <Input
                                className="cust-input"
                                maxLength={100}
                                placeholder="Please specify:"
                            />
                            </Form.Item>
                      </Col>}
            </>
        }
        return _templates[transferType]
    }
    render() {
      
        const { transferType,domesticType } = this.props;
        return <>
            <Row gutter={[16, 16]} className={'pb-16'}>
            {this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />}
            {this.renderAddress(domesticType === "internationalIBAN" ? "sepa" : transferType)}
            </Row>
            </>

    }
}
export default PayeeBankDetails;