import { Component } from 'react'
import Layout from 'antd/lib/layout';
import Typography from 'antd/lib/typography';
const { Footer: AntFooter } = Layout
const { Text } = Typography;
class AppFooter extends Component {

    render() {
        return (<AntFooter style={{ backgroundColor: 'transparent', padding: 0 }}>
        <div className="main-container">
            <div className="footer-links">
                <a href={process.env.REACT_APP_SUISSEBASE_HOME} target="_blank"><Text className="text-white-30">Home</Text></a>
               
                <Text className="text-white-30">SuisseBase<sup>TM</sup>{"  "} {new Date().getFullYear()}</Text>
            </div>
        </div>
    </AntFooter>)
    }
}

export default AppFooter;