import React, { Component } from 'react';
import { Typography,Button, Col, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import masterCard from '../../assets/images/mastercard.png';
import cardlogo from '../../assets/images/cardlogo.png';
import wallster from '../../assets/images/WallesterLogo.svg';

const {Title, Text } = Typography;
class SbCard extends Component {
    handleApplyCard=(val)=>{
      if(val=="sb-card"){
        window.open(process.env.REACT_APP_ACCOUNT_APPLY_CARD, '_blank')
      }else{
        window.open(process.env.REACT_APP_WALLESTER, '_blank')
      }
    }
    render() {
        return (<>
        <div>            
        <h1 content="sb_card_title" className="db-titles your-card mt-5" >Your Card </h1>
        <Row gutter={16}>
        <Col xs={24} md={12} lg={9} xl={9} className="cust-col-design">
                <div className='sb-card'>                   
                <div className="card-bottom-style d-flex ">
                <img src={cardlogo} alt={"masterCard"} className='rounded-full' />
                    <Text>XAPO BANK</Text></div>                   
               
                <div className="card-bottom-style d-flex align-center  justify-content mt-87"> <Button
                          type="primary"
                          className="custom-btn prime"
                        
                          onClick={()=>this.handleApplyCard("sb-card")}
                        >
                          Apply For A Card
                        </Button>
                <div>
                <img src={masterCard} alt={"masterCard"} />
                <p className='text-center lg-fontsize text-white master-text fw-600'>mastercard</p>
                </div>
                </div>
                </div></Col>
                <Col xs={24} md={12} lg={9} xl={9} className="cust-col-design">
                <div className='sb-card bg-wallester'>                   
                <div className="card-bottom-style d-flex ">
                <img src={wallster} alt={"masterCard"} className='rounded-full' height={45} />
                    <Text>Wallester</Text></div>                   
               
                <div className="card-bottom-style d-flex align-center  justify-content mt-87"> <Button
                          type="primary"
                          className="custom-btn prime"
                        
                          onClick={()=>this.handleApplyCard("Wallester-card")}
                        >
                          Apply For A Card
                        </Button>
                <div>
                <img src={masterCard} alt={"masterCard"} />
                <p className='text-center lg-fontsize text-white master-text fw-600'>Visa</p>
                </div>
                </div>
                </div>
                </Col>
                </Row>
                </div>
               
        </>);
    }

}
export default withRouter(SbCard);
