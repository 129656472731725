import React, { Component } from "react";
import success from "../../assets/images/success.svg";
import { Typography, Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Title from "antd/lib/skeleton/Title";

const { Paragraph } = Typography;

class ProgressPage extends Component {
  render() {
    return (
      <>
        <div
          className="custom-createaccount creat-bg thank-u"
          style={{ margin: "36px auto" }}
        >
          <div className="success-pop text-center">
            <img src={success} className="confirm-icons" alt={"success"} />
            <Title className="success-title">Thank You</Title>
            <Paragraph className="business-thankyoutext">
            Your account creation is in progress. Contact</Paragraph>
            <Paragraph className="business-thankyoutext">
              <a href="mailto:compliance@suissebase.io"><span className='gridLink c-pointer	'>{process.env.REACT_APP_MAIL_URL}</span></a> for more details
            </Paragraph>
        
            <Button
              type="button"
              className="c-pointer text-center ant-btn-lg text-white-30 mt-12 fw-400 text-captz text-center ant-btn-primary custom-btn prime"
              onClick={() => this.props.history.push(`/dashboard/banks/${this.props.match?.params?.currency}/false/${this.props.match?.params?.isbank}`)}
            >Go to Dashboard
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const connectStateToProps = ({ userConfig }) => {
  return {
    userConfig: userConfig.userProfileInfo
  };
};
const connectDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};
export default connect(
  connectStateToProps,
  connectDispatchToProps
)(withRouter(ProgressPage));
