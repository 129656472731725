import { apiMain, bankClient } from "../../api/clients";
import { ApiControllers } from "../../api/config";

const fetchIBANDetails = (iban,bankName) => {
    if(bankName=="pyrros"){
        return apiMain.get(ApiControllers.master + `GetIBANAccountDetails?ibanNumber=${iban}`+" &isopenpayd=true")
    }else{
        return apiMain.get(ApiControllers.master + `GetIBANAccountDetails?ibanNumber=${iban}`)
    }
}
const createPayee = ( addr_book_id, address_type) => {
    addr_book_id = addr_book_id || "00000000-0000-0000-0000-000000000000";
    return bankClient.get(ApiControllers.addressbook + `payee/Withdraw/Favorite/${addr_book_id}/${address_type}`)
}
const payeeAccountObj = () => {
    return {
        "id": "00000000-0000-0000-0000-000000000000",
        "line1": "",
        "line2": "",
        "city": "",
        "state": "",
        "country": "",
        "postalCode": "",
        "currencyType": "",
        "walletCode": "",
        "accountNumber": "",
        "swiftRouteBICNumber": "",
        "bankName": "",
        "userCreated": "",
        "iban": "",
        "bic": "",
        "bankBranch": "",
        "abaRoutingCode": "",
        "documents": null
    }


}
const document = () => {
    return {
        "id": "00000000-0000-0000-0000-000000000000",
        "typeId": "",
        "transactionId": "",
        "path": "",
        "adminId": "",
        "date": "",
        "type": "",
        "customerId": "",
        "note": "",
        "remarks": "",
        "status": true,
        "state": "",
        "info": "",
        "details": [

        ]
    }
}

const savePayee = (obj) => {
    return bankClient.post(ApiControllers.addressbook + `payee`, obj);
}
const confirmTransaction = (obj) => {
    return bankClient.post(ApiControllers.bank + `Account/AccountBalanceConfirm`, obj);
}
const fetchPayees = (currency) => {
    return bankClient.get(ApiControllers.addressbook + `PayeeLu/${currency}`);
}

const fetchPastPayees = (currency) => {
    return bankClient.get(ApiControllers.bank + `Payee/${currency}`);
}
const updatePayee = (obj) => {
    return apiMain.post(ApiControllers.addressbook + `UpdatePayee`, obj);
}
const saveWithdraw = (obj,screen) => {
    if(screen?.toLowerCase()=="openpayd"){
        return bankClient.post(ApiControllers.bank + `openpaydTransfer`, obj);
    }else{
        return bankClient.post(ApiControllers.bank + `Transfer`, obj);
    }
}
const validateAmount = (obj) => {
    return bankClient.post(ApiControllers.bank + `Account/AccountDetails`, obj)
}
const fetchMemberWallets = () => {
    return apiMain.get(ApiControllers.wallets + `FiatDashboard/Exchange`);
}
const downloadAttachment = (transactionId) =>{
    return bankClient.get(ApiControllers.bank+`ConfirmationSlip/${transactionId}`)
}
const getRelationDetails = () => {
    return bankClient.get(ApiControllers.bank + `controlcodeslu/Relationship To Beneficiary`)
}
const getReasonforTransferDetails = () => {
    return bankClient.get(ApiControllers.bank + `controlcodeslu/Reason For Transfer`)
}
const saveCommision = (obj) => {
    return bankClient.post(ApiControllers.bank + `Fiat/commision`,obj)
}
const getBanlLuData = () =>{
    return bankClient.get(ApiControllers.bank+`CustomerBanks/all`)
}
const getTabsDetails = () =>{
	return bankClient.get(ApiControllers.bank + `AddressBookTabs`)
  }
export { fetchIBANDetails, createPayee, payeeAccountObj, document, savePayee, confirmTransaction, fetchPayees, fetchPastPayees, updatePayee, saveWithdraw, validateAmount, fetchMemberWallets,
    downloadAttachment,getRelationDetails,getReasonforTransferDetails,saveCommision,getBanlLuData,getTabsDetails };
